import React from 'react';
//import ReactDOM from 'react-dom';
import { render } from "react-dom";

import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import { Provider } from "react-redux";
import { getStore } from "./store";

//import * as serviceWorker from './serviceWorker';

import './common/css/font-awesome.min.css';

import Home from './components/flight/Home/Home';
import Adminprofile from './components/flight/Account/Adminprofile';
import Login from './components/flight/Account/Login';
import Logout from "./components/flight/Account/Logout";
import Setting from "./components/flight/Account/Setting";
import Changepassword from "./components/flight/Account/Changepassword";
import Forgetpassword from "./components/flight/Account/Forgetpassword";
import Resetpassword from "./components/flight/Account/Resetpassword";
import Company from "./components/flight/Company/List";
import CompanyAdd from "./components/flight/Company/Add"
import CompanyEdit from "./components/flight/Company/Edit";

import UserList from "./components/flight/User/List";
import UserEdit from "./components/flight/User/Edit";
import UserAdd from "./components/flight/User/Add";
import UserImport from "./components/flight/User/ImportUser";
import Fontlist from "./components/flight/Fonts/List";
import FontAdd from "./components/flight/Fonts/Add";
import FontEdit from "./components/flight/Fonts/Edit";

//import BrokerImport from "./components/flight/Broker/ImportBrokers";
import BrokerList from "./components/flight/Broker/List";
import BrokerEdit from "./components/flight/Broker/Edit";

import TemplateList from './components/flight/Templates/List';
import TemplateAdd from './components/flight/Templates/Add';
import TemplateEdit from './components/flight/Templates/Edit';

import CmsList from "./components/general/Cms/List";
import CmsAdd from "./components/general/Cms/Add";
import CmsEdit from "./components/general/Cms/Edit";

import Notification from "./components/general/Usernotification/Notification";


import QuoteList from "./components/flight/Quote/List";
import QuoteView from "./components/flight/Quote/View";

import DirectPaymentList from "./components/flight/Directpayment/List";
import DirectPaymentView from "./components/flight/Directpayment/View";

import PaymentList from "./components/general/Payment/List";
////


import './common/css/custom.css';


const store = getStore();
render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/profile" component={Adminprofile} />
        <Route path="/logout" component={Logout} />
        <Route path="/setting" component={Setting} />
        <Route path="/changepassword" component={Changepassword} />
        <Route path="/forget_password" component={Forgetpassword} />
        <Route path="/reset_password/:key" component={Resetpassword} />
        <Route path="/company" component={Company} />
        <Route path="/company-add" component={CompanyAdd} />
        <Route path="/company-edit/:ID" component={CompanyEdit} />

        <Route path="/broker" component={BrokerList} />
        <Route path="/editbroker/:ID" component={BrokerEdit} />
        
        <Route path="/templates" component={TemplateList} />
        <Route path="/template/add" component={TemplateAdd} />
        <Route path="/templates-edit/:ID" component={TemplateEdit} />
        <Route path="/fonts" component={Fontlist} />
        <Route path="/font-add" component={FontAdd} />
        <Route path="/font/edit/:ID" component={FontEdit} />
        <Route path="/user" component={UserList} />
        <Route path="/edit/:ID" component={UserEdit} />
        <Route path="/user-add" component={UserAdd} />
        <Route path="/user-import" component={UserImport} />
        <Route path="/cms/edit/:ID" component={CmsEdit} />
        <Route path="/cms" component={CmsList} />
        <Route path="/cms-add" component={CmsAdd} />
		<Route path="/notification" component={Notification} />

        <Route path="/quotes" component={QuoteList} />
        <Route path="/quotes-view/:ID" component={QuoteView} />
		
		<Route path="/directpayment" component={DirectPaymentList} />
        <Route path="/directpayment-view/:ID" component={DirectPaymentView} />
		
        <Route path="/payment" component={PaymentList} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);