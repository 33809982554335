import React, { Component, useState } from "react";
import $ from 'jquery';
 
class Addinputsimple extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
    	domainName: [
        ],
        admin_id:localStorage.getItem("admin_id"),
        removekey:'',
        domain_id:'',
        removeID:[
        ],
		domainStyle:{ color: "",width: "85%"}
		
    };
	
	 

  }
  
  componentDidMount() {
      /* this.setState({domainName: [
            {                
                domainis: "Home",
            },
            {               
                domainis: "Gallery",
            },
            {               
                domainis: "test",
            }
        ]});
		*/
	 this.props.myCallback(this.state.domainName);
	 this.handleError  = this.handleError.bind(this);

    }
	componentWillReceiveProps(NextProps) {
		  
		  if (
      NextProps.domainname !== "" &&
      typeof NextProps.domainname !== "undefined" &&
      NextProps.domainname !== this.state.domainname
    ) {
      this.setState({ domainName: NextProps.domainname });
    }
	
   }

  addClick(){
    this.setState(prevState => ({ 
    	domainName: [...prevState.domainName, { domain_name: "" }]
    }))
  }
  
  createUI(){
     
	  const mystyle1 = {
		  color: "white",
		  padding: "14px",		  
		  minWidth: "47px",
		  height: "48px",
		  backgroundColor: "#051f3a",
		  borderRadius: "0 5px 5px 0"
		};
		
	// var k = 0;
			const domainlist =this.state.domainName.map(
            (category, key) => {
              let sno = key+1;
			  var errorclass = "errorsettings_domain_title"+sno;
              return (
               <div className="form-group" key={key}>
						<label>Domain Url {sno}:</label>
						<div className={errorclass} >
						 <input style={this.state.domainStyle} type="text" name="domain_name" onChange={this.handleChange.bind(this, key, category.domain_id)} autoComplete="off" className="form-control" value={category.domain_name || ''} />
						  <button style={mystyle1} onClick={this.cancelConfirmPopup.bind(this, key,category.domain_id)}type="button" ><i class="fa fa-times" aria-hidden="true"></i></button>
						 </div>					
					 </div>
              );
            }
          );
          return domainlist;    		
  }
  
  handleChange(i, id, e) {
  	 const { name, value } = e.target;
     let domainName = [...this.state.domainName];
     domainName[i] = {...domainName[i], [name]: value};
	 this.props.myCallback(domainName, this.state.removeID);
	 this.setState({ domainName });
	 this.handleError(i,value);
  }
  
  handleError(i,settings_domain){
	var pattern = new RegExp("^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}$");
	var sn = i+1;
	var errorclass = ".errorsettings_domain_title"+sn;
    $(errorclass +" input").css({"border-color": ""});
	$(errorclass +" button").css({"background-color": ""});	
	 if(settings_domain !=''){
		  if(pattern.test(settings_domain)){
				$(errorclass +" input").css({"border-color": ""});
				$(errorclass +" button").css({"background-color": ""});	
			}else{
				//$(errorclass).html('<span class="errorspan">Incorrect domain name</span>');	
				$(errorclass +" input").css({"border-color": "#e64949"});	
				$(errorclass +" button").css({"background-color": "#e64949"});			
			}	
	 }
  }
  

    cancelConfirmPopup = (i,domainID) => {
		this.setState({removekey: i, domain_id: domainID})	
		$('.confirm-action').addClass('show');		
	}

	cancelRideConfirm = (confirmstatus) => {
		if(confirmstatus == 'yes'){
			this.removeClick(this.state.removekey,this.state.domain_id);
			$('.confirm-action').removeClass('show');
		}else{
			$('.confirm-action').removeClass('show');	
		}
			
	}


  removeClick(i,domainID){	
  	
     let domainName = [...this.state.domainName];
     domainName.splice(i, 1);
	 let { removeID } = this.state;
  	 removeID.push(domainID);
  	 this.setState({removeID : removeID})
	 this.props.myCallback(domainName,removeID);
     this.setState({ domainName });
  }
  
 

  render() {
	  let mystyle = {
		  color: "white",
		  backgroundColor: "DodgerBlue",
		  padding: "10px",		  
			minWidth: "49px",
			height: "37px",
			textTransform: "capitalize"
	
		};
		
		
    return (
      <div>
		<div className="confirm-action">
			<p>Do you want to remove your active domain? If yes, you will lose your registered users and drivers.</p>
			<a href="javascript://" onClick={this.cancelRideConfirm.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.cancelRideConfirm.bind(this, "no")} className="btn">No</a>
		</div>
          {this.createUI()}
		  {(this.state.admin_id == '1' && this.state.domainName && Object.keys(this.state.domainName).length < 5)?
		  <button href="#" style={mystyle} onClick={this.addClick.bind(this)} type="button" > <i class="fa fa-plus" aria-hidden="true"></i> Add</button>:''
		 
		  }
		  {(this.state.admin_id !== '1' && this.state.domainName && Object.keys(this.state.domainName).length < 2)?
		  <button href="#" style={mystyle} onClick={this.addClick.bind(this)} type="button" > <i class="fa fa-plus" aria-hidden="true"></i> Add</button>:''
		  }
		   <div className="errDomain"></div>
     </div>
    );
  }
}
 
export default Addinputsimple;