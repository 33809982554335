import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../../general/Layouts/Header';
import MenuSidebar from '../../general/Layouts/MenuSidebar';

import { GET_COMPANYLIST  } from '../../../actions';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      companylist:''
    };
    this.props.getCompanyList();
    }

   
   componentDidMount() {
   }


   componentWillReceiveProps(Props){

    if (Object.keys(Props.companylist).length > 0) {
      if (Props.companylist[0]["status"] === "success") {
        this.setState({
          companylist: Props.companylist[0]["companylist"],
        });
      }
    }
     
   }

  companylist() {
    var companylist = this.props.companylist[0];
    if (companylist != "undefined" && companylist != null) {
      if (companylist.status == "success") {
        if (Object.keys(companylist).length > 0) {
          const companylistDetails = companylist.companylist.map(
            (companylist, Index) => {
              let sno = Index+1;
              if(companylist.admin_status == 'Yes'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={companylist.admin_id}>
					<td>{sno}</td>
					<td>
					{companylist.admin_company_name}
					</td>                 
					<td>{companylist.admin_email_address}</td>
					<td>{companylist.admin_contact}</td>
				
					<td>{companylist.admin_username}</td>
					<td>{companylist.admin_org_password}</td>
                   <td>{status}</td>
                  <td className="actiongroup">

                    <Link to={{ pathname: '/company-edit/'+companylist.admin_id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                   
                  </td>
                </tr>
              );
            }
          );
          return companylistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="companylist" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div className="car-listing-wrap">

        <div className="listing-header"> 
          <div className="title">
            <h3>Companies</h3>
          </div> 
          <div className="add_button">  
              <a className="add" href='/company-add'>Add</a> 
          </div>
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Company</th>               
				<th>Email</th>
				<th>Contact</th>
				<th>Username</th>
				<th>Password</th>
               
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.companylist()}</tbody>
          </Table>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    companylist: state.companylist,
   // deletecompany: state.deletecompany

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyList: () =>{
       dispatch({ type: GET_COMPANYLIST });
    },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));