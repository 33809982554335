/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDUSER, SET_ADDUSER, GET_USERLIST,SET_USERLIST,GET_EDITUSER,SET_EDITUSER,GET_DELETEUSER, SET_DELETEUSER, GET_ACTIVATEUSER, SET_ACTIVATEUSER, GET_EXPORTUSER, SET_EXPORTUSER,GET_USERDETAILLIST, SET_USERDETAILLIST,GET_IMPORTUSER,SET_IMPORTUSER } from '../../actions';
import {apiUrl } from "../../components/general/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*export const watchGetUserList = function* () {
  yield takeEvery(GET_USERLIST, workerGetUserList);
}

function* workerGetUserList() {
	  
      var url = apiUrl+'user/list';
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_USERLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}*/


/*save the user*/

export const watchGetUserList = function* () {
  yield takeEvery(GET_USERLIST, workerGetUserList);
}

function* workerGetUserList({ formVal }) {
  try {
      
 const result = yield call(getUserList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_USERLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getUserList(formPayload) {
   return Axios.post(apiUrl+'user/list', formPayload);
} 




/*get user details*/ 

export const watchGetUserDetailList = function* () {
  yield takeEvery(GET_USERDETAILLIST, workerGetUserDetailList);
}

function* workerGetUserDetailList({ userid }) {

    var url = apiUrl+'user/listdetail?id='+userid;
    try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_USERDETAILLIST, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
} 


/*save the user*/

export const watchGetAddUser = function* () {
  yield takeEvery(GET_ADDUSER, workerGetAddUser);
}

function* workerGetAddUser({ formPayload }) {
  try {
      console.log(formPayload)
 const result = yield call(getAddUser, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDUSER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddUser(formPayload) {
   return Axios.post(apiUrl+'user/add', formPayload);
} 


/*edit the user*/

export const watchGetEditUser = function* () {
  yield takeEvery(GET_EDITUSER, workerGetEditUser);
}

function* workerGetEditUser({ formPayload }) {
  try {
      
 const result = yield call(getEditUser, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITUSER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditUser(formPayload) {
   return Axios.post(apiUrl+'user/edit', formPayload);
} 



/*IMPORT the user*/

export const watchGetImportUser = function* () {
  yield takeEvery(GET_IMPORTUSER, workerGetImportUser);
}

function* workerGetImportUser({ formPayload }) {
  try {
      
 const result = yield call(getImportUser, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_IMPORTUSER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
}

function getImportUser(formPayload) {
   return Axios.post(apiUrl+'user/import', formPayload);
} 


/*delete the user*/

export const watchGetDeleteUser = function* () {
  yield takeEvery(GET_DELETEUSER, workerGetDeleteUser);
}

function* workerGetDeleteUser({ formPayload }) {
  try {
      
 const result = yield call(getDeleteUser, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEUSER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteUser(formPayload) {
   return Axios.post(apiUrl+'user/delete', formPayload);
} 


/*change status the user*/

export const watchActivateUser = function* () {
  yield takeEvery(GET_ACTIVATEUSER, workerActivateUser);
}

function* workerActivateUser({ formPayload }) {
  try {
      
 const result = yield call(getActivateUser, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ACTIVATEUSER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getActivateUser(formPayload) {
   return Axios.post(apiUrl+'user/change_status', formPayload);
} 


/*Export the user*/

export const watchExportUser = function* () {
  yield takeEvery(GET_EXPORTUSER, workerExportUser);
}

function* workerExportUser({ formPayload }) {
  try {
      
 const result = yield call(getExportUser, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EXPORTUSER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 

function getExportUser(formPayload) {
   return Axios.post(apiUrl+'user/export', formPayload);
}