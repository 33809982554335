import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id')
		};
		
    }
	
	activeMenu(currentPage) {
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'active'
            }else{
            status = 'enable';
        }
        return status;
    }
	
  render() {
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu">
	   <Scrollbars className="custom-scroll-wrap">
			<ul className="menulist">
				<li className={this.activeMenu('dashboard')}>
					<a title="Dashboard" href="#">
						<i className="dashboard-icon icon"></i><span>Dashboard</span>
					</a>
				</li>						
				
				<li className={this.activeMenu('templates')}>
					<a title="Templates" href="/templates">
						<i className="template-icon icon" aria-hidden="true"></i>
						<span>Templates</span>
					</a>
				</li>
				
				<li className={this.activeMenu('landing_cms')}>
					<a title="CMS" href="/cms">
						<i className="cms-icon icon" aria-hidden="true"></i>
						<span>Plugin Contents</span>
					</a>
				</li>
				<li className={this.activeMenu('notification')}>
					<a title="Notification" href="/notification">
						<i className="notification-icon icon" aria-hidden="true"></i>
						<span>User Notifications</span>
					</a>
				</li>
				<li className={this.activeMenu('fonts')}>
					<a title="Fonts" href="/fonts">
						<i className="font-icon icon" aria-hidden="true"></i>
						<span>Fonts</span>
					</a>
				</li>				
				

				<li className={this.activeMenu('user')}>
					<a title="Users" href="/user">
						<i className="userlist-icon icon" aria-hidden="true"></i>
						<span>Users</span>
					</a>
				</li>
				
				<li className={this.activeMenu('brokers')}>
					<a title="Broker" href="/broker">
						<i className="userlist-icon icon" aria-hidden="true"></i>
						<span>Brokers</span>
					</a>
				</li>

				<li className={this.activeMenu('quotes')}>
					<a title="Quotes" href="/quotes">
						<i className="quote-icon icon" aria-hidden="true"></i>
						<span>Quotations </span>
					</a>
				</li>
				
				
				<li className={this.activeMenu('payment')}>
					<a title="Payment" href="/payment">
						<i className="payment-icon icon" aria-hidden="true"></i>
						<span>Broker Payments</span>
					</a>
				</li>
				
				<li className={this.activeMenu('directpayment')}>
					<a title="Direct Payment" href="/directpayment">
						<i className="payment-icon icon" aria-hidden="true"></i>
						<span>Direct Payments</span>
					</a>
				</li>
				
				<li className={this.activeMenu('settings')}>
					<a title="Settings" href="/setting">
						<i className="setting-icon icon" aria-hidden="true"></i>
						<span>Settings</span>
					</a>
				</li>
				
				
			</ul>
			</Scrollbars>
		</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;