import { SET_DIRECTPAYMENTLIST, SET_DIRECTPAYMENTVIEWLIST } from '../../actions';

export const directpaymentlist = (state = [], action) => {
  switch (action.type) {
	case SET_DIRECTPAYMENTLIST:
      return [...action.value];
    default: return state;
  }
}

export const directpaymentview = (state = [], action) => {
  switch (action.type) {
	case SET_DIRECTPAYMENTVIEWLIST:
      return [...action.value];  
    default: return state;
  }
}


//export default directpaymentlist;
