/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import {  GET_ADDCMS, SET_ADDCMS, GET_CMSLIST, SET_CMSLIST, GET_EDITCMS, SET_EDITCMS, GET_CMSDETAIL, SET_CMSDETAIL,GET_ACTIVATECMS, SET_ACTIVATECMS} from '../../actions';
import {apiUrl } from "../../components/general/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


export const watchGetAddCms = function* () {
  yield takeEvery(GET_ADDCMS, workerGetAddCms);
}

function* workerGetAddCms({ formPayload }) {
  try {
      
 const result = yield call(getAddCms, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDCMS, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddCms(formPayload) {
   return Axios.post(apiUrl+'cms/add', formPayload);
} 


/*list*/



export const watchGetCmsList = function* () {
  yield takeEvery(GET_CMSLIST, workerGetCmsList);
}

function* workerGetCmsList({ formVal }) {
  try {
      
 const result = yield call(getCmsList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_CMSLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getCmsList(formVal) {
   return Axios.post(apiUrl+'cms/list', formVal);
} 


/*get Cms detail*/

export const watchGetCmsDetail = function* () {
  yield takeEvery(GET_CMSDETAIL, workerGetCmsDetail);
}

function* workerGetCmsDetail({ cmsId }) {
  var url = apiUrl+'cms/cmsdetail?id='+cmsId;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CMSDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  


/*edit the vehicle*/

export const watchGetEditCms = function* () {
  yield takeEvery(GET_EDITCMS, workerGetEditCms);
}

function* workerGetEditCms({ formPayload }) {
  try {
      
 const result = yield call(getEditCms, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITCMS, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditCms(formPayload) {
   return Axios.post(apiUrl+'cms/edit', formPayload);
} 



/*delete the cms*/

export const watchGetDeleteCms = function* () {
  yield takeEvery(GET_DELETECMS, workerGetDeleteCms);
}

function* workerGetDeleteCms({ formPayload }) {
  try {
      
 const result = yield call(getDeleteCms, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETECMS, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteCms(formPayload) {
   return Axios.post(apiUrl+'cms/delete', formPayload);
} 


/*change status the user*/

export const watchActivateCms = function* () {
  yield takeEvery(GET_ACTIVATECMS, workerActivateCms);
}

function* workerActivateCms({ formPayload }) {
  try {
      
 const result = yield call(getActivateCms, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ACTIVATECMS, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getActivateCms(formPayload) {
   return Axios.post(apiUrl+'cms/change_status', formPayload);
} 