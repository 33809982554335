import { all } from "redux-saga/effects";
import { watchGetLoginData } from "./flight/login";
import { watchGetSettingData, watchGetUpdateSetting }from "./flight/setting";
import { watchGetChangePassword }from "./flight/changepassword";
import { watchGetForgetPassword } from "./flight/forgetpassword";
import { watchGetProfileDetails, watchGetUpdateProfile } from './flight/profile';
import {  watchGetCompanyList,watchGetAddCompany, watchGetEditCompany  } from './flight/company';
import {watchGetUserList, watchActivateUser, watchExportUser,watchGetEditUser, watchGetUserDetailList,watchGetAddUser,watchGetImportUser} from './flight/user';
import { watchGetTemplateList, watchGetTemplateDetail, watchGetTemplateAdd, watchGetTemplateDelete, watchGetTemplateEdit } from './flight/template';
import { watchGetFontList, watchGetFontName, watchGetAddFont, watchGetDeleteFont } from './flight/font';

import { watchGetDomainList } from './flight/common';

import { watchGetBrokerList,watchActivateBroker, watchExportBroker, watchGetEditBroker, watchGetBrokerDetail,watchGetDeleteBroker,  watchStripLinkToBroker } from './flight/broker';

import { watchGetQuoteList,watchGetViewQuoteList } from './flight/quote';
import { watchGetDirectPaymentList,watchGetViewDirectPaymentList } from './flight/directpayment';

import { watchPaymentLog } from './general/package';

import { watchGetAddCms, watchGetCmsList, watchGetEditCms, watchGetDeleteCms, watchGetCmsDetail,watchActivateCms } from './general/cms';
import { watchGetNotification, watchGetUpdateNotification } from './general/notification';

export default function*() {
  yield all([
        watchGetLoginData(),
        watchGetSettingData(),      
        watchGetUpdateSetting(),
        watchGetChangePassword(),
        watchGetForgetPassword(),
        watchGetProfileDetails(),
        watchGetUpdateProfile(),       
        watchGetCompanyList(),
        watchGetAddCompany(),      
        watchGetEditCompany(),
        watchGetFontList(),      
        watchGetFontName(),
        watchGetAddFont(),
        watchGetUserList(),
        watchActivateUser(),
        watchExportUser(),
        watchGetDeleteFont(),
        watchGetEditUser(),  
        watchGetUserDetailList(),
        watchGetAddUser(),
        watchGetImportUser(),	    
        watchGetTemplateList(),
        watchGetTemplateDetail(),
        watchGetTemplateAdd(),
        watchGetTemplateDelete(),
        watchGetTemplateEdit(),
        watchGetAddCms(),
        watchGetCmsList(),
        watchGetEditCms(),
        watchGetCmsDetail(),
        watchActivateCms(),
        watchGetQuoteList(),
       watchGetViewQuoteList(),
	   watchGetDirectPaymentList(),
       watchGetViewDirectPaymentList(),
       watchGetBrokerList(),
       watchActivateBroker(),
       watchExportBroker(),
       watchGetEditBroker(),
       watchGetBrokerDetail(),      
         watchGetDeleteBroker(),
       watchStripLinkToBroker(), 
       watchPaymentLog(),
	   watchGetNotification(),
	   watchGetUpdateNotification()
  ]);
}
