export const GET_LOGINDATA         = "getLoginData";
export const SET_LOGINDATA 		   = "setLoginData";
export const GET_SETTINGDATA 	   = "getSettingData";
export const SET_SETTINGDATA 	   = "setSettingData";
export const GET_UPDATESETTING     = "getUpdateSetting";
export const SET_UPDATESETTING     = "setUpdateSetting";
export const GET_CHANGEPASSWORD     = "getChangePassword";
export const SET_CHANGEPASSWORD     = "setChangePassword";
export const GET_FORGETPASSWORD     = "getForgetPassword";
export const SET_FORGETPASSWORD     = "setForgetPassword";
export const GET_PROFILEDETAILS     = "getProfileDetails";
export const SET_PROFILEDETAILS     = "setProfileDetails";
export const GET_UPDATEPROFILE     = "getUpdateProfile";
export const SET_UPDATEPROFILE     = "setUpdateProfile";


/*Company module*/
export const GET_ADDCOMPANY         = "getAddCompany";
export const SET_ADDCOMPANY         = "setAddCompany";
export const GET_EDITCOMPANY        = "getEditCompany";
export const SET_EDITCOMPANY        = "setEditCompany";
export const GET_COMPANYLIST        = "getCompanyList";
export const SET_COMPANYLIST        = "setCompanyList";
export const GET_DELETECOMPANY      = "getDeleteCompany";
export const SET_DELETECOMPANY      = "setDeleteCompany";
/*end*/

export const GET_EXPORTUSER         = "getExportuser";
export const SET_EXPORTUSER         = "setExportuser";


/*user module*/
export const GET_ADDUSER         = "getAddUser";
export const SET_ADDUSER         = "setAddUser";
export const GET_EDITUSER        = "getEditUser";
export const SET_EDITUSER        = "setEditUser";
export const GET_USERLIST        = "getUserList";
export const SET_USERLIST        = "setUserList";
export const GET_DELETEUSER      = "getDeleteUser";
export const SET_DELETEUSER      = "setDeleteUser";

export const GET_IMPORTUSER       = "getImportUser";
export const SET_IMPORTUSER       = "setImportUser";

export const GET_ACTIVATEUSER        = "getActivateUser";
export const SET_ACTIVATEUSER        = "setActivateUser";

export const GET_USERDETAILLIST      = "getUserDetailList";
export const SET_USERDETAILLIST      = "setUserDetailList";

/*end*/

/*car*/

export const GET_ADDCAR              = "getAddCar";
export const SET_ADDCAR              = "setAddCar"; 
export const GET_EDITCAR             = "getEditCar";
export const SET_EDITCAR             = "setEditCar";
export const GET_CARLIST             = "getCarList";
export const SET_CARLIST             = "setCarList";

export const GET_CARDETAIL           = "getCarDetail";
export const SET_CARDETAIL           = "setCarDetail";

export const GET_DELETECAR           = "getDeleteCar";
export const SET_DELETECAR           = "setDeleteCar";
/**/

export const GET_PRIORITYUPDATE      = "getPriorityUpdate";
export const SET_PRIORITYUPDATE      = "setPriorityUpdate";
/**/

/*get fonts*/

export const GET_FONTLIST            = "getFontList";
export const SET_FONTLIST            = "setFontList";


export const GET_FONTNAME            = "getFontName";
export const SET_FONTNAME            = "setFontName";

export const GET_ADDFONT             = "getAddFont";
export const SET_ADDFONT             = "seAddFont";

export const GET_DELETEFONT           = "getDeleteFont";
export const SET_DELETEFONT           = "setDeleteFont";


/*broker module*/
export const GET_ADDBROKER         = "getAddBroker";
export const SET_ADDBROKER         = "setAddBroker";
export const GET_EDITBROKER        = "getEditBroker";
export const SET_EDITBROKER        = "setEditBroker";
export const GET_BROKERLIST        = "getBrokerList";
export const SET_BROKERLIST        = "setBrokerList";

export const GET_BROKERDROPDOWNLIST        = "getBrokerDropDownList";
export const SET_BROKERDROPDOWNLIST        = "setBrokerDropDownList";
export const GET_ASSIGNBROKER        = "getAssignBroker";
export const SET_ASSIGNBROKER        = "setAssignBroker";

export const GET_BROKERDETAIL      = "getBrokerDetail";
export const SET_BROKERDETAIL      = "setBrokerDetail";

export const GET_DELETEBROKER      = "getDeleteBroker";
export const SET_DELETEBROKER      = "setDeleteBroker";

export const GET_ACTIVATEBROKER      = "getActivateBroker";
export const SET_ACTIVATEBROKER      = "setActivateBroker";

export const GET_STRIPELINKTOBROKER      = "getstripelinkToBroker";
export const SET_STRIPELINKTOBROKER      = "setstripelinkToBroker";

export const GET_EXPORTBROKER        = "getExportbroker";
export const SET_EXPORTBROKER        = "setExportbroker";

export const GET_IMPORTBROKER       = "getImportBroker";
export const SET_IMPORTBROKER       = "setImportBroker";
/*end*/

/*Template Module*/

export const GET_TEMPLATELIST        = "getTemplateList";
export const SET_TEMPLATELIST        = "setTemplateList";
export const GET_TEMPLATEDETAIL        = "getTemplateDetail";
export const SET_TEMPLATEDETAIL        = "setTemplateDetail";
export const GET_TEMPLATEADD           = "getTemplateAdd";
export const SET_TEMPLATEADD           = "setTemplateAdd";
export const GET_TEMPLATEEDIT           = "getTemplateEdit";
export const SET_TEMPLATEEDIT           = "setTemplateEdit";
export const GET_TEMPLATEDELETE        = "getTemplateDelete";
export const SET_TEMPLATEDELETE        = "setTemplateDelete";

/*get domain list for user and broker add*/
export const GET_DOMAINLIST =  "getDomainList";
export const SET_DOMAINLIST =  "setDomainList";


/*Cms*/

export const GET_ADDCMS              = "getAddCms";
export const SET_ADDCMS              = "setAddCms"; 
export const GET_EDITCMS             = "getEditCms";
export const SET_EDITCMS             = "setEditCms";
export const GET_CMSLIST             = "getCmsList";
export const SET_CMSLIST             = "setCmsList";

export const GET_CMSDETAIL           = "getCmsDetail";
export const SET_CMSDETAIL           = "setCmsDetail";

export const GET_DELETECMS           = "getDeleteCms";
export const SET_DELETECMS           = "setDeleteCms";

export const GET_ACTIVATECMS         = "getActivateCms";
export const SET_ACTIVATECMS         = "setActivateCms";

/*Notification*/
export const GET_USERNOTIFICATION = "getUsernotification";
export const SET_USERNOTIFICATION = "setUsernotification";
export const GET_UPDATENOTIFICATION = "getUpdateUsernotification";
export const SET_UPDATENOTIFICATION = "setUpdateUsernotification";


/*Quote module */
export const GET_QUOTELIST     = "getQuoteList";
export const SET_QUOTELIST     = "setQuoteList";
export const GET_QUOTEVIEWLIST   = "getQuoteViewList";
export const SET_QUOTEVIEWLIST   = "setQuoteViewList";

/*Directpayment module */
export const GET_DIRECTPAYMENTLIST     = "getDirectPaymentList";
export const SET_DIRECTPAYMENTLIST     = "setDirectPaymentList";
export const GET_DIRECTPAYMENTVIEWLIST   = "getDirectPaymentViewList";
export const SET_DIRECTPAYMENTVIEWLIST   = "setDirectPaymentViewList";

/*get payment log*/
export const GET_PAYMENTLOG =  "getPaymentLog";
export const SET_PAYMENTLOG =  "setPaymentLog";

export const categoriesOptions = [
  { value: "Fly", label: "Fly" },
  { value: "Hotel", label: "Hotel" },
  { value: "Car", label: "Car"},
  { value: "Package", label: "Package"},
  { value: "Fun", label: "Fun"}
];
export const ticketOptions = [
	{ value: "Economy", label: "Economy"},
	{ value: "Business", label: "Business" },
	{ value: "Premium Economy", label: "Premium Economy" },  
	{ value: "First", label: "First"}
];

