/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_ADDBROKER, SET_ADDBROKER, GET_BROKERLIST,SET_BROKERLIST,GET_EDITBROKER,SET_EDITBROKER,GET_DELETEBROKER, SET_DELETEBROKER, GET_ACTIVATEBROKER, SET_ACTIVATEBROKER, GET_EXPORTBROKER, SET_EXPORTBROKER, GET_BROKERDETAIL, SET_BROKERDETAIL, GET_STRIPELINKTOBROKER, SET_STRIPELINKTOBROKER } from '../../actions';
import {apiUrl,sampleFileUrl } from "../../components/general/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

/*get BROKER detail*/

export const watchGetBrokerDetail = function* () {
  yield takeEvery(GET_BROKERDETAIL, workerGetBrokerDetail);
}

function* workerGetBrokerDetail({ brokerid }) {
  var url = apiUrl+'broker/brokerdetails?id='+brokerid;
  try {
    const uri = url;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data)
    yield put({ type: SET_BROKERDETAIL, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

/*get broker list*/
export const watchGetBrokerList = function* () {
  yield takeEvery(GET_BROKERLIST, workerGetBrokerList);
}

function* workerGetBrokerList({ formVal }) {
  try {
      
 const result = yield call(getBrokerList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_BROKERLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getBrokerList(formPayload) {
   return Axios.post(apiUrl+'broker/list', formPayload);
} 

/*save the user*/

export const watchGetAddUser = function* () {
  yield takeEvery(GET_ADDUSER, workerGetAddUser);
}

function* workerGetAddUser({ formPayload }) {
  try {
      
 const result = yield call(getAddUser, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ADDUSER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getAddUser(formPayload) {
   return Axios.post(apiUrl+'user/add', formPayload);
} 


/*edit the user*/

export const watchGetEditBroker = function* () {
  yield takeEvery(GET_EDITBROKER, workerGetEditBroker);
}

function* workerGetEditBroker({ formPayload }) {
  try {
      
 const result = yield call(getEditBroker, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EDITBROKER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getEditBroker(formPayload) {
   return Axios.post(apiUrl+'broker/edit', formPayload);
} 


/*delete the user*/

export const watchGetDeleteBroker = function* () {
  yield takeEvery(GET_DELETEBROKER, workerGetDeleteBroker);
}

function* workerGetDeleteBroker({ formPayload }) {
  try {
      
 const result = yield call(getDeleteBroker, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DELETEBROKER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getDeleteBroker(formPayload) { console.log('test');
   return Axios.post(apiUrl+'broker/delete', formPayload);
} 


/*change status the user*/

export const watchActivateBroker = function* () {
  yield takeEvery(GET_ACTIVATEBROKER, workerActivateBroker);
}

function* workerActivateBroker({ formPayload }) {
  try {
      
 const result = yield call(getActivateBroker, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_ACTIVATEBROKER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 

/*change status the user*/

export const watchStripLinkToBroker = function* () {
  yield takeEvery(GET_STRIPELINKTOBROKER, workerStripLinkToBroker);
}

function* workerStripLinkToBroker({ formPayload }) {
  try {
      
 const result = yield call(getStripLinkToBroker, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_STRIPELINKTOBROKER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
}


function getStripLinkToBroker(formPayload) {
   return Axios.post(apiUrl+'broker/generate_stripe_connective_link', formPayload);
} 


/*Export the user*/

export const watchExportBroker = function* () {
  yield takeEvery(GET_EXPORTBROKER, workerExportBroker);
}

function* workerExportBroker({ formPayload }) {
  try {
      
 const result = yield call(getExportBroker, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_EXPORTBROKER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 

function getExportBroker(formPayload) {
   return Axios.post(apiUrl+'broker/export', formPayload);
}



/*
export const watchGetImportBroker = function* (){
	yield takeEvery(GET_IMPORTBROKER, workerGetImportBroker);
}

function* workerGetImportBroker({ formPayload }) {
  try {
      
 const result = yield call(getImportBroker, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_IMPORTBROKER, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
}

function getImportBroker(formPayload) {
   return Axios.post(apiUrl+'broker/import', formPayload);
} 
*/

/*get broker detail*/
