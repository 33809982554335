export const lang = {
    customer : {
        
    },
    common: {
        title:'AirPal',
        status_option:[
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
		discount_option:[
            { value: 'percentage', label: 'Percentage' },
            { value: 'standard', label: 'Standard' },
        ],
        gender_option: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' }
        ],
		image_size_option: [
            { value: '250x250', label: '250x250' },
			{ value: '350x350', label: '350x350' },
            { value: '520x520', label: '520X520' }
        ],
        coming_source :[
            { value: '0', label: 'Select' },
            { value: 'friends', label: 'Friends' },
            { value: 'facebook', label: 'Facebook' },
            { value: 'instagram', label: 'Instagram' },
            { value: 'messaging apps', label: 'Messaging Apps' },
            { value: 'website', label: 'Website' },
            { value: 'flyers', label: 'Flyers' },
            { value: 'sms', label: 'Sms' },
            { value: 'newspaper', label: 'Newspapers' },
            { value: 'others', label: 'Others' }
        ],
        expired: 'Expired',
        more_info: 'More Info',
        no_trans:'No transaction found',
        enter_amount : 'Enter Amount',
        send : 'Send',
        accept : 'Accept',
        gentrun: 'GenRun',
        genpro: 'GenPro',
        genproperty: 'GenProperty',
    }
};


