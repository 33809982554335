/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_PAYMENTLOG, SET_PAYMENTLOG } from '../../actions';
import {apiUrl } from "../../components/general/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


/*payment log for driver tip*/

export const watchPaymentLog = function* () {
  yield takeEvery(GET_PAYMENTLOG, workerPaymentLog);
}

function* workerPaymentLog({ formVal }) {
  try {
      
 const result = yield call(getPaymentLog, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_PAYMENTLOG, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getPaymentLog(formVal) {
   return Axios.post(apiUrl+'plugin/payment_log', formVal);
} 