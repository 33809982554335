import { SET_FONTLIST, SET_ADDFONT, SET_DELETEFONT, SET_FONTNAME } from '../../actions';

export const fontlist = (state = [], action) => {
  switch (action.type) {
	case SET_FONTLIST:
      return [...action.value];  
    default: return state;
  }
}

export const fontadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDFONT:
      return [...action.value];  
    default: return state;
  }
}

export const fontdelete = (state = [], action) => {
  switch (action.type) {
	case SET_DELETEFONT:
      return [...action.value];  
    default: return state;
  }
}

export const fontname = (state = [], action) => {
  switch (action.type) {
	case SET_FONTNAME:
      return [...action.value];  
    default: return state;
  }
}