import React, { Component } from 'react';


import Header from '../../general/Layouts/Header';
import MenuSidebar from '../../general/Layouts/MenuSidebar';
//import Files from '../Files/Files';


class Home extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
		};
		
    }
	
	componentDidMount() {
		  	if(localStorage.getItem("admin_id") === 'undefined' && localStorage.getItem('admin_id') === '' && localStorage.getItem('admin_id') === null){
			this.props.history.push('/');
		}
    }
	
  render() {
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar />		

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			
			
			
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default Home;