import { SET_SETTINGDATA, SET_UPDATESETTING } from '../../actions';

export const settings = (state = [], action) => {
  switch (action.type) {
	case SET_SETTINGDATA:
      return [...action.value];  
    default: return state;
  }
}
/*=============*/
export const updatesetting = (state = [], action) => {
  switch (action.type) {
	case SET_UPDATESETTING:
      return [...action.value];  
    default: return state;
  }
}
