import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import axios from 'axios';

import Header from '../../general/Layouts/Header';
import MenuSidebar from '../../general/Layouts/MenuSidebar';
import Pagination from "react-js-pagination";

import { scrollToTopValidate,PageTitle } from "../../general/Helpers/SettingHelper";

import { GET_PAYMENTLOG } from '../../../actions';
import moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
        paymentlog:'',
        quotetypelist:[],
        editable: false,
        priorityid:'',
        priorityvalue:'',
        activePage: 1,
        totalRecord: '',
        search_all:''
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    search_type:'',
    admin_id: admin_id,
    loggedas: loggedas
    };
    this.props.getPaymentLog(qs.stringify(postobject));
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
    componentDidMount() {
       document.title = PageTitle('Payment List');
    }

   handlePageChange(pageNumber) {

     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");

     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       search_type: this.state.search_type,
       admin_id: admin_id,
       loggedas: loggedas
     };
     this.props.getPaymentLog(qs.stringify(postobject))
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    search_type: formPayload.search_type,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  this.props.getPaymentLog(qs.stringify(postObject));

} 



   componentWillReceiveProps(Props){

    if (Object.keys(Props.paymentlog).length > 0) {
      if (Props.paymentlog[0]["status"] === "success") {
        this.setState({ totalRecord : Props.paymentlog[0].records_count, paymentlog: Props.paymentlog[0]["paymentlog"] });
        this.paymentlog();
      }
    }
     
   }

   handleClickSelecetType = (event) => {

    const selectedIndex = event.target.options.selectedIndex;
    console.log(event.target.options);
    var key = event.target.options[selectedIndex].getAttribute('data-key');
  
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
  
    var postObject = {             
      activePage   : 1,
      search_type : key,
      admin_id: admin_id,
      loggedas: loggedas         
    };
  
    this.props.getPaymentLog(qs.stringify(postObject));
  
    this.setState({
        Loading:true     
    })
  
  }
   paymentlog() {
    var paymentlog = this.props.paymentlog[0];
    if (paymentlog != "undefined" && paymentlog != null) {
      if (paymentlog.status == "success") {
        if (Object.keys(paymentlog).length > 0) {
          const helperlistDetails = paymentlog.paymentlist.map(
            (paymentlog, Index) => {

            let sno = Index+1;
            var istyle = { width: 100};
           
         var payment_type = paymentlog.payment_type == null ?  paymentlog.payment_type = 'N/A' : paymentlog.payment_type;
         var paid_status = paymentlog.paid_status == null ?  paymentlog.paid_status = 'N/A' : paymentlog.paid_status;

         var ride_status = paymentlog.ride_status == 'tostart' ?  paymentlog.paid_status = 'Assigned' : paymentlog.ride_status;
          var username = paymentlog.username == null ?  paymentlog.username = 'N/A' : paymentlog.username;
          return (
                <tr key={paymentlog.id}>
          <td>{sno}</td>
          <td>{paymentlog.booking_id }</td>
          <td>{paymentlog.type}</td>
          <td>{paymentlog.u_name}</td>
          <td>{username}</td>
          <td>{paymentlog.from_location}</td>
          <td>{paymentlog.to_location}</td>
          <td>{paymentlog.passenger_count}</td>
           
          <td>${paymentlog.original_amount}</td>
          <td>{String(paymentlog.req_type).charAt(0).toUpperCase() + String(paymentlog.req_type).slice(1)}</td>
         
          <td>{payment_type}</td>
         
          <td>{moment(paymentlog.date).format('MM/DD/YYYY')}</td>   
          {/*<td className="actiongroup">
              <Link to={{ pathname: '/quotes-view/'+paymentlog.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
            </td>*/}
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="15" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="15" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {
    const {quotetypelist} = this.state;
    var current = this;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="payment" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                 <h3>Broker Payments</h3>
                </div>

                <div className="form-group">
                <select  onChange={current.handleClickSelecetType}  value={this.state.search_type}> 
                <option data-key="">Search by quotation type</option>
                <option data-key="Hotel">Hotel</option>
                 <option data-key="Fly">Fly</option>
                  <option data-key="Car">Car</option>
                   <option data-key="Package">Package</option>
                    <option data-key="Fun">Fun</option>
                </select>
              <div className="errorassign_quotetype"></div>
              </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> 
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			            <th>Quotation ID</th>
                  <th>Type</th>
                  <th>User</th>
                   <th>Broker</th>
                  <th>From location</th>
                  <th>To location</th>
                  <th>Passengers</th>
                  <th>Amount</th>
                  <th>Payment Request</th>
                  <th>Payment Type</th>
                  <th>Paid On</th>
                   </tr>
                  </thead>
                  <tbody>{this.paymentlog()}</tbody>
                </Table>
                </div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    paymentlog: state.paymentlog,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
     getPaymentLog: (formVal) =>{
       dispatch({ type: GET_PAYMENTLOG, formVal });
    },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));