import { SET_USERNOTIFICATION, SET_UPDATENOTIFICATION } from '../../actions';

export const notification = (state = [], action) => {
  switch (action.type) {
	case SET_USERNOTIFICATION:
      return [...action.value];  
    default: return state;
  }
}

export const updatenotification = (state = [], action) => {
  switch (action.type) {
	case SET_UPDATENOTIFICATION:
      return [...action.value];  
    default: return state;
  }
}
