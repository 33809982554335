/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_QUOTELIST, SET_QUOTELIST, GET_QUOTEVIEWLIST, SET_QUOTEVIEWLIST } from '../../actions';
import {apiUrl } from "../../components/general/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


/*get Quote list*/

export const watchGetQuoteList = function* () {
  yield takeEvery(GET_QUOTELIST, workerGetQuoteList);
}

function* workerGetQuoteList({ formVal }) {   
  try {     
 const result = yield call(getQuoteList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_QUOTELIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 

function getQuoteList(formPayload) {
   return Axios.post(apiUrl+'broker/quotelist', formPayload);
} 

  /*view Quote*/
export const watchGetViewQuoteList = function* () {
    yield takeEvery(GET_QUOTEVIEWLIST, workerGetViewQuoteList);
  }
  
  function* workerGetViewQuoteList({ quoteid }) {
    try {
      const result = yield call(getQuoteViewList, quoteid);
      var resultArr = [];
      resultArr.push(result.data);
      yield put({ type: SET_QUOTEVIEWLIST, value: resultArr });
    } 
    catch {
      console.log('Get Page Failed');
    }
  }  
  
  function getQuoteViewList(quoteid){
     return Axios.post(apiUrl+'broker/quoteview', quoteid);
  }