import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Header extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			admin_name : localStorage.getItem("admin_name"),
			admin_id : localStorage.getItem("admin_id"),
			admin_company_name : localStorage.getItem("admin_company_name"),
		};
		    this.logout  = this.logout.bind(this);

    }
    logout(){
    	localStorage.removeItem('admin_id');
    	localStorage.removeItem('admin_name');
    	this.props.history.push('/logout');
      }
	
	componentDidMount() {
		if (localStorage.getItem("admin_id") === null) {
			this.props.history.push('/');
		}
    }
	
  render() {
    return (
	<div className="header">	
	  <div className="navbar-wrapper">
	  
		    <div className="logo-box mobi-close">
				<a href="index.html" className="logo">
					<span className="logo-lg">{this.state.admin_company_name && this.state.admin_company_name}</span>
					<span className="logo-sm">AP</span>
				</a>
			</div>	
			
			<div className="header-cnt">
			<div className="header-cnt-left">			
				<a href="javascript:void(0)" className="trigger-menu menu-icon">
                    <span className="icon_menu1"></span>
                    <span className="icon_menu2"></span>
                    <span className="icon_menu3"></span>
                </a>	
			</div>
			<div className="header-cnt-right">	
				<div className="myaccount">	
				<div className="myaccount-inner">
					<a href="javascript:void(0)" alt="user" className="user-name" >Welcome! {this.state.admin_name}</a>
					<a href="javascript:void(0)" alt="mail" className="dropdown-toggle user-icon r-icon">
						<i className="u-icon icon"></i>
					</a>
				</div>	
					<ul className="user-menu">
						<li>
							<a href="/profile" title="Upload Docs">
								<i className="profile-icon icon"></i> <span>Profile</span>
							</a>
						</li>
						<li>
							<a href="/changepassword" title="Upload Docs">
								<i className="profile-icon icon"></i> <span>Change Password</span>
							</a>
						</li>
						<li>
						 <a
                                href="javascript:void(0);"
                                onClick={e => {
                                  e.preventDefault();
                                  this.logout();
                                }}
                              >
								<i className="logout-icon icon"></i> <span>Logout</span>
							</a>
						</li>
					</ul>
				</div>	
			</div>		
			</div>			
			
		</div>
	</div>
	
    );
  }
}


const mapStateTopProps = (state) => {
  return {
	
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Header));