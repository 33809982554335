import { SET_TEMPLATELIST, SET_TEMPLATEDETAIL, SET_TEMPLATEADD, SET_TEMPLATEEDIT, SET_TEMPLATEDELETE } from '../../actions';

export const templatelist = (state = [], action) => {
  switch (action.type) {
	case SET_TEMPLATELIST:
      return [...action.value];  
    default: return state;
  }
}
/*=========*/

export const templatedetail = (state = [], action) => {
  switch (action.type) {
	case SET_TEMPLATEDETAIL:
      return [...action.value];  
    default: return state;
  }
}
/*=========*/

export const templateadd = (state = [], action) => {
  switch (action.type) {
	case SET_TEMPLATEADD:
      return [...action.value];  
    default: return state;
  }
}
/*=========*/

export const templateedit = (state = [], action) => {
  switch (action.type) {
	case SET_TEMPLATEEDIT:
      return [...action.value];  
    default: return state;
  }
}
/*=========*/

export const templatedelete = (state = [], action) => {
  switch (action.type) {
	case SET_TEMPLATEDELETE:
      return [...action.value];  
    default: return state;
  }
}
/*=========*/
