 /*local */

// export const apiUrl          = "http://localhost/airpal/api2/";
// export const baseUrl         = "http://localhost/airpal";
// export const carImageUrl     = "http://localhost/airpal/media/airpal";
// export const sampleFileUrl   = "http://localhost/airpal/media/sample_file/";
// export const dummycarUrl     = 'http://localhost/airpal/media/others';
// export const cmscontentURL   = 'http://localhost/airpal/media/plugincontent';

// export const licenseURL       = "http://localhost/airpal/media/brokerlicense";
// export const insuranceURL       = "http://localhost/airpal/media/brokerinsurance";
// export const profileURL       = "http://localhost/airpal/media/brokerprofile";
// export const brokerURL       = "http://localhost/airpal/media/brokercarimage";
// export const galleryUrl      = 'http://localhost/airpal/media/gallery/';
// export const favouritesUrl      = 'http://localhost/airpal/media/favourites'; 

/*Live */


export const apiUrl           = "https://admin-air.uxt.design/airpal/api2/";
export const baseUrl          = "https://admin-air.uxt.design/airpal";
export const carImageUrl      = "https://admin-air.uxt.design/airpal/media/airpal";;
export const sampleFileUrl    = "https://admin-air.uxt.design/airpal/media/sample_file/";
export const dummycarUrl      = 'https://admin-air.uxt.design/airpal/media/others';
export const cmscontentURL    = 'https://admin-air.uxt.design/airpal/media/plugincontent';

export const licenseURL       = "https://admin-air.uxt.design/airpal/media/brokerlicense";
export const insuranceURL     = "https://admin-air.uxt.design/airpal/media/brokerinsurance";
export const profileURL       = "https://admin-air.uxt.design/airpal/media/brokerprofile";
export const brokerURL        = "https://admin-air.uxt.design/airpal/media/brokercarimage";
export const galleryUrl       = 'https://admin-air.uxt.design/airpal/media/gallery/';
export const favouritesUrl    = 'https://admin-air.uxt.design/airpal/media/favourites';








