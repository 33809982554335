/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_DIRECTPAYMENTLIST, SET_DIRECTPAYMENTLIST, GET_DIRECTPAYMENTVIEWLIST, SET_DIRECTPAYMENTVIEWLIST } from '../../actions';
import {apiUrl } from "../../components/general/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';


/*get DIRECTPAYMENT list*/

export const watchGetDirectPaymentList = function* () {
  yield takeEvery(GET_DIRECTPAYMENTLIST, workerGetDirectPaymentList);
}

function* workerGetDirectPaymentList({ formVal }) {   
  try {     
 const result = yield call(getDirectPaymentList, formVal);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_DIRECTPAYMENTLIST, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 

function getDirectPaymentList(formPayload) {
   return Axios.post(apiUrl+'broker/directpaymentlist', formPayload);
} 

  /*view DirectPayment*/
export const watchGetViewDirectPaymentList = function* () {
    yield takeEvery(GET_DIRECTPAYMENTVIEWLIST, workerGetViewDirectPaymentList);
  }
  
  function* workerGetViewDirectPaymentList({ quoteid }) {
    try {
      const result = yield call(getDirectPaymentViewList, quoteid);
      var resultArr = [];
      resultArr.push(result.data);
      yield put({ type: SET_DIRECTPAYMENTVIEWLIST, value: resultArr });
    } 
    catch {
      console.log('Get Page Failed');
    }
  }  
  
  function getDirectPaymentViewList(quoteid){
     return Axios.post(apiUrl+'broker/directpaymentview', quoteid);
  }