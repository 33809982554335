/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_PROFILEDETAILS, SET_PROFILEDETAILS,GET_UPDATEPROFILE ,SET_UPDATEPROFILE } from '../../actions';
import {apiUrl } from "../../components/general/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

export const watchGetProfileDetails = function* () {
  yield takeEvery(GET_PROFILEDETAILS, workerGetProfileDetails);
}

function* workerGetProfileDetails({ adminId }) {
  try {
    console.log(adminId)
    const uri = apiUrl+'adminpanel/getadminProfile?adminId='+adminId;

    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PROFILEDETAILS, value: resultArr });
  } 
  catch {
    console.log('Get Page Failed');
  }
}  

/*save the profile*/

export const watchGetUpdateProfile = function* () {
  yield takeEvery(GET_UPDATEPROFILE, workerGetUpdateSetting);
}

function* workerGetUpdateSetting({ formPayload }) {
  try {
      
 const result = yield call(getUpdateProfile, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_UPDATEPROFILE, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getUpdateProfile(formPayload) {
   return Axios.post(apiUrl+'adminpanel/profilesave', formPayload);
} 