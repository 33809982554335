import { SET_COMPANYLIST, SET_ADDCOMPANY, SET_EDITCOMPANY } from '../../actions';

export const companylist = (state = [], action) => {
  switch (action.type) {
	case SET_COMPANYLIST:
      return [...action.value];  
    default: return state;
  }
}
/*=========*/
export const companyadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDCOMPANY:
      return [...action.value];  
    default: return state;
  }
}
/*=========*/
export const companyedit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITCOMPANY:
      return [...action.value];  
    default: return state;
  }
}

