import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../../general/Layouts/Header';
import MenuSidebar from '../../general/Layouts/MenuSidebar';
import Pagination from "react-js-pagination";

import { scrollToTopValidate,PageTitle } from "../../general/Helpers/SettingHelper";
import { GET_QUOTEVIEWLIST } from '../../../actions';
import moment from 'moment';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const quoteid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      quoteid: quoteid,
      quoteview:'',
    };

    var qs = require('qs');
    var postObject = {             
    id   :quoteid
    };
    this.props.getQuoteViewList(qs.stringify(postObject));  
  }

   
   componentDidMount() {
     document.title = PageTitle('Quote View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
      if(Props.quoteview!==this.state.quoteview){
        if(Props.quoteview[0].status == "success"){
          this.setState({quoteview: Props.quoteview[0].quoteview[0],quotelist: Props.quoteview[0].quoteview}) 
        }
      }
   }

    quotelist() {
    var quotelist = this.state.quotelist;
    if (quotelist != "undefined" && quotelist != null) {
      
        if (Object.keys(quotelist).length > 0) {
          const helperlistDetails = quotelist.map(
            (quotelist, Index) => {
              let sno = Index+1;
              var istyle = { width: 100};
              var payment_type = quotelist.payment_type == null ?  quotelist.payment_type = 'N/A' : quotelist.payment_type;
         var paid_status = quotelist.paid_status == null ?  quotelist.paid_status = 'N/A' : quotelist.paid_status;
          var brokername = quotelist.brokername == null ?  quotelist.brokername = 'N/A' : quotelist.brokername;
          var brokeremail = quotelist.brokeremail == null ?  quotelist.brokeremail = 'N/A' : quotelist.brokeremail;
          var brokermobileno = quotelist.brokermobileno == null ?  quotelist.brokermobileno = 'N/A' : quotelist.brokermobileno;
            var ride_status = quotelist.ride_status == 'tostart' ?  quotelist.paid_status = 'Assigned' : quotelist.ride_status;
              return (
                
                <div className="form-row direct-payment">
                   <div className="direct-payment-inner">
                  
                 
                 <div className="payment-histroy details_left">
                 <div className="view-details">
                        <div className="view-details-one">
                          <p><b>Quotation ID<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">   
                          <p>{quotelist.booking_id}</p>
                          </div>
                          </div>

                           <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Quotation Type<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{quotelist.type}</p>
                      </div>
                      </div>
                     <div className="view-details">
                        <div className="view-details-one">
                          <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">   
                          <p>{quotelist.u_name}</p>
                          </div>
                          </div>
                          <div className="view-details">
                        <div className="view-details-one">
                      <p><b>User Email<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{quotelist.u_email}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>User Contact<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{quotelist.u_mobile}</p>
                      </div>
                      </div>
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Broker Name<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{brokername}</p>
                      </div>
                      </div>
                        <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Broker Email<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{brokeremail}</p>
                      </div>
                      </div>
                        <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Broker Contact<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{brokermobileno}</p>
                      </div>
                      </div>

                       <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Passenger<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{quotelist.passenger_count}</p>
                      </div>
                      </div>

                        <div className="view-details">
                        <div className="view-details-one">
                      <p><b>From Location<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{quotelist.from_location}</p>
                      </div>
                      </div>


                       <div className="view-details">
                        <div className="view-details-one">
                      <p><b>To Location<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{quotelist.to_location}</p>
                      </div>
                      </div>
                      

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b> Amount<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>${quotelist.original_amount}</p>
                      </div>
                      </div>
                    
                   <div className="view-details">
                        <div className="view-details-one">
                      <p><b> Status<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{String(ride_status).charAt(0).toUpperCase() + String(ride_status).slice(1)}</p>
                      </div>
                      </div>
                     
                       <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Paid Status<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{paid_status}</p>
                      </div>
                      </div>

                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Payment Type<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{String(payment_type).charAt(0).toUpperCase() + String(payment_type).slice(1)}</p>
                      </div>
                      </div>
                      
                       

                      

                      
                      <div className="view-details">
                        <div className="view-details-one">
                      <p><b>Quoted On<span>:</span> </b></p>
                      </div>
                      <div className="view-details-two">
                      <p>{moment(quotelist.date).format('MM/DD/YYYY')}</p>
                      </div>
                      </div>
                     
                      
                    </div>
                  </div>
                 </div>
              
              );
            }
          );
          return helperlistDetails;
        }
      
    }
  }


  
  render() {

   

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="payment-history" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="content-body">
               <div className="form-wrapper">
                <div className="title">
                  <h3> Quote Details</h3>
                </div>
                    {this.quotelist()}
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    quoteview: state.quoteview
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getQuoteViewList: (quoteid) =>{
       dispatch({ type: GET_QUOTEVIEWLIST,quoteid });
    },
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(View));