/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import Header from '../../general/Layouts/Header';
import MenuSidebar from '../../general/Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { GET_SETTINGDATA, GET_UPDATESETTING, GET_FONTNAME, categoriesOptions, ticketOptions } from '../../../actions';

/*===import code for multiselect starts here===*/
import Select from 'react-select';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
/*===import code for multiselect ends here===*/


import { scrollToTopValidate, PageTitle,scrollToTop } from "../../general/Helpers/SettingHelper";
import Addinputsimple from './Addinputsimple';
import { carImageUrl,apiUrl } from '../../general/Config/Config';
import axios from 'axios';


class Setting extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			settings_id:'',
			settings_site_title:'',
			settings_from_name:'',
			settings_admin_email:'',
			settings_from_email:'',
			settings_mailpath:'',
			settings_company_address:'',
			settings_commission_percentage:'',
			settings_smtp_host:'',
			settings_smtp_user:'',
			settings_smtp_pass:'',
			settings_smtp_port:'',
			settings_username:'',
			settings_mobileno:'',
			settings_email_footer:'',
			smtp_checked:false,
			Loading: false,
			displayColorPicker: false,
			color: {
			r: '241',
			g: '112',
			b: '19',
			a: '1',
			},
			hexColor:'#f17013',
			settings_public_key:'',
			settings_secret_key:'',
			stripe_live_checked:false,
			stripe_sandbox_checked:false,
			settings_domain_one:'',
			settings_domain_two:'',
			settings_embedcode:'',
			settings_access_code:'',
			st_position_left:'',
			st_position_right:'',
			st_position_top:'',
			st_position_bottom:'',
			bookingid_prefix:'',
			bookingid_prefix_readonly:'',
			loader_text:'',
			invite_friends_perc:'',
			domainSets:'',
			removeID:[
        	],
			user_image: [],
			user_image_name: "",
			user_image_preview: "",
			loader_image: [],
			loader_image_name: "",
			loader_image_preview: "",
			fonttype : [],
			selectedFont:'',
			fontvalue:'',
			wherelocation_checked:true,
			pickuplocation_checked:false,
			settings_location_input:'',
			admin_id:localStorage.getItem("admin_id"),
			removeddomainId:'',
			styleimported:'',
			stylefontfamily:'',
			st_position_checked:true,
			st_position:'',
			 optionSelected: null,
			 optionSelectedSeparted: null,
			 optionTicketSelected: null,
			 optionTicketSelectedSeparted: null,
			 showFlightTicket: false,
			
		};
		var qs = require('qs');
		
		var postobject = {
			admin_id:  localStorage.getItem("admin_id")
		};
		this.props.getSettingData(qs.stringify(postobject));
		this.props.getFontName();
		this.handleInputChange  = this.handleInputChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.handleChangeFont = this.handleChangeFont.bind(this);
		
    }

    handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'settings_mail_from_smtp'){
      	 this.setState({smtp_checked: !this.state.smtp_checked});
      }	
      if(name === 'settings_enable_live'){
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      	  this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      }	
      if(name === 'settings_enable_sandbox'){
      	 this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      	       	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});

      }	
      if(name === 'settings_enable_droplocation'){
      	 this.setState({wherelocation_checked: !this.state.wherelocation_checked});
      	  this.setState({pickuplocation_checked: !this.state.pickuplocation_checked});
      }	
      if(name === 'settings_enable_pickuplocation'){
      	 this.setState({pickuplocation_checked: !this.state.pickuplocation_checked});
      	       	 this.setState({wherelocation_checked: !this.state.wherelocation_checked});

      }
      if(name === 'st_position'){
      	this.setState({st_position_checked: !this.state.st_position_checked});
      }	
    }
	
	

	
 handlemultiChange = (selected) => {	 
	var arrObj = [];
	var setboolen = false;
	var obj = JSON.stringify(selected, function(key, value) {
		
		var multiSelectSplit = value.map ((x) => (
			arrObj.push(x.value),
			(x.value == 'Fly' ? (setboolen = true) : '')
		));
	}); 
	
    this.setState({
      optionSelected: selected,
      optionSelectedSeparted: arrObj,
      showFlightTicket: setboolen
    });
  };
  
  handlemultiTicketChange = (selected) => {	 
	var arrObj = [];
	var obj = JSON.stringify(selected, function(key, value) {
		var multiSelectSplit = value.map ((x) => (
			arrObj.push(x.value)
		));
	}); 
	
    this.setState({
      optionTicketSelected: selected,
      optionTicketSelectedSeparted: arrObj
    });
  };
  

    componentDidMount() {
      document.title = PageTitle('Settings');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
	   
     }
	 
   onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	  this.setState({
		user_image_name: file.name,
		user_image_preview: reader.result,
		user_image: file
	  });
	};
	reader.readAsDataURL(file);
  }
  
  onChangeLoaderHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	reader.onloadend = () => {
	  this.setState({
		loader_image_name: file.name,
		loader_image_preview: reader.result,
		loader_image: file
	  });
	};
	reader.readAsDataURL(file);
  }

    handleFormSubmit = () => {

			if(this.validateForm()){
				this.setState({Loading:true});
				
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				  
				const formPayload = this.state;
				var qs = require('qs');
				if(this.state.smtp_checked === true){
					var settings_mail_from_smtp = '1';
				}else{
					 settings_mail_from_smtp = '0';
				}
				if(this.state.stripe_live_checked === true){
					var settings_enable_live = '1';
				}else{
					 settings_enable_live = '0';
				}

				var default_location = '';
				if(this.state.wherelocation_checked === true){
					 default_location = 'where_location';
				}else{
					 default_location = 'pickup_location';
				}


				var fonttype;
				if(formPayload.selectedFont[0]==undefined){
					fonttype = formPayload.selectedFont.value;
				}else{
					fonttype = formPayload.selectedFont[0].value;
				}

				if(this.state.st_position_checked === true){
					var plugin_position = 'fixed';
				}else{
					 plugin_position = 'absolute';
				}

				var postObject = {
					settings_id: formPayload.settings_id,
					settings_site_title:formPayload.settings_site_title,
					settings_from_name:formPayload.settings_from_name,
					settings_admin_email:formPayload.settings_admin_email,
					settings_from_email:formPayload.settings_from_email,
					settings_mailpath:formPayload.settings_mailpath,
					settings_company_address:formPayload.settings_company_address,
					settings_commission_percentage:formPayload.settings_commission_percentage,
					site_background: formPayload.hexColor,
					smtp_host:formPayload.settings_smtp_host,
					smtp_user:formPayload.settings_smtp_user,
					smtp_pass:formPayload.settings_smtp_pass,
					smtp_port:formPayload.settings_smtp_port,
					admin_user:formPayload.settings_username,
					admin_no:formPayload.settings_mobileno,
					settings_email_footer:formPayload.settings_email_footer,
					settings_mail_from_smtp:settings_mail_from_smtp,
					settings_public_key:formPayload.settings_public_key,
					settings_secret_key:formPayload.settings_secret_key,
					settings_enable_live:settings_enable_live,
					st_position_left:formPayload.st_position_left,
					st_position_right:formPayload.st_position_right,
					st_position_top:formPayload.st_position_top,
					st_position_bottom:formPayload.st_position_bottom,
					bookingid_prefix:formPayload.bookingid_prefix,
					invite_friends_perc:formPayload.invite_friends_perc,
					font 		  : fonttype,
					default_location_point :  default_location,
					settings_location_input: formPayload.settings_location_input,
					st_ap_icon: formPayload.user_image,
					st_ap_loader: formPayload.loader_image,
					st_ap_loader_text: formPayload.loader_text,
					plugin_position: plugin_position,
					removeId: formPayload.removeID,
					optionSelected: formPayload.optionSelected,
					optionSelectedSeparted: formPayload.optionSelectedSeparted,
					optionTicketSelected: formPayload.optionTicketSelected,
					optionTicketSelectedSeparted: formPayload.optionTicketSelectedSeparted
				};
				console.log(postObject)
				let formData = new FormData();
				for(let k in postObject) {
				  formData.append(k, postObject[k]);
				}

				var domains = formPayload.domainSets;

				for(let i in domains){

					if(domains[i]['domain_id'] ==  'undefined'){
						formData.append('domainName['+ i + '][domain_id]','null')		
					}else if(domains[i]['domain_id'] !==  undefined && domains[i]['domain_id'] !==  ''){
						formData.append('domainName['+ i + '][domain_id]',domains[i]['domain_id'])
					}

					formData.append('domainName['+ i + '][domain_name]',domains[i]['domain_name'])
					formData.getAll('domainName');
				}
				
				this.props.getUpdateSetting(formData,config);
				////this.props.getUpdateSetting(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {settings_site_title,settings_from_name,settings_admin_email,settings_from_email,settings_company_address,settings_commission_percentage,settings_smtp_host,settings_smtp_user,settings_smtp_pass,settings_smtp_port,settings_username,settings_mobileno,settings_email_footer,settings_public_key,settings_secret_key,settings_domain_one,settings_domain_two,settings_embedcode,user_image_preview,selectedFont,settings_location_input,bookingid_prefix,invite_friends_perc,optionSelected} = this.state;
		let errors = 0;
      	//let formIsValid = true;
		if (!settings_site_title) { 
			errors++;
			$('.errorsettings_site_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_site_title){		
			$('.errorsettings_site_title').html('');
		}
		
		if (!settings_from_name) {
			errors++;
			$('.errorsettings_from_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_name){
			$('.errorsettings_from_name').html('');
		}
		
		if (!settings_admin_email) {
			errors++;
			$('.errorsettings_admin_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_admin_email){
			$('.errorsettings_admin_email').html('');
		}
		if (!settings_from_email) {
			errors++; 
			$('.errorsettings_from_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_email){
			$('.errorsettings_from_email').html('');
		}
		if (!settings_username) {
			errors++;
			$('.errorsettings_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_username){
			$('.errorsettings_username').html('');
		}

		if (!settings_mobileno) {
			errors++;
			$('.errorsettings_mobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_mobileno){
			$('.errorsettings_mobileno').html('');
		} 

		if (!settings_email_footer) { 
			errors++;
			$('.errorsettings_email_footer').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_company_address){
			$('.errorsettings_email_footer').html('');
		}
		var letters = /^[a-zA-Z0-9\d\-_.\s]+$/;
		if (!bookingid_prefix) {
			errors++;
			$('.errorbookingid_prefix').html('<span class="errorspan">Please fill the field</span>');
		}else if(bookingid_prefix.length > 10) {
			errors++;
			$('.errorbookingid_prefix').html('<span class="errorspan">Only 10 characters allowed</span>');
		}else if(!bookingid_prefix.match(letters)) 
		  {
			errors++;
			$('.errorbookingid_prefix').html('<span class="errorspan">Alphanumeric and Special Characters like "- _ ." are allowed</span>');
		 }else{
			$('.errorbookingid_prefix').html('');
		}
		
		var num = /^[0-9\d\.\s]+$/;
		if (!invite_friends_perc) {
			errors++;
			$('.errorinvite_friends_perc').html('<span class="errorspan">Please fill the field</span>');
		}else if(invite_friends_perc.length > 4) {
			errors++;
			$('.errorinvite_friends_perc').html('<span class="errorspan">Only 4 characters allowed</span>');
		}else if(!invite_friends_perc.match(num)) 
		  {
			errors++;
			$('.errorinvite_friends_perc').html('<span class="errorspan">Numeric like "0.5" are allowed</span>');
		 }else{
			$('.errorinvite_friends_perc').html('');
		}

		/*if (!settings_company_address) {
			errors++; console.log('settings_company_address');
			$('.errorsettings_company_address').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_company_address){
			$('.errorsettings_company_address').html('');
		}*/
		if (!settings_commission_percentage) { 
			errors++;
			$('.errorsettings_commission_percentage').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_commission_percentage){
			$('.errorsettings_commission_percentage').html('');
		}

		if (!settings_location_input) {
			errors++;
			$('.errorsettings_input_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_location_input){
			$('.errorsettings_input_key').html('');
		}
		
		
		if (optionSelected == null || optionSelected == '') {
			errors++;
			$('.erroroptionSelected').html('<span class="errorspan">Please select the field</span>');
		}else{			
			$('.erroroptionSelected').html('');
		}

		/*if (!settings_smtp_pass) {
			errors++;
			$('.errorsettings_smtp_pass').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_pass){
			$('.errorsettings_smtp_pass').html('');
		}

		if (!settings_smtp_host) {
			errors++;
			$('.errorsettings_smtp_host').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_host){
			$('.errorsettings_smtp_host').html('');
		}

		if (!settings_smtp_port) {
			errors++;
			$('.errorsettings_smtp_port').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_smtp_port){
			$('.errorsettings_smtp_port').html('');
		}
		if (!settings_public_key) {
			errors++;
			$('.errorsettings_public_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_public_key){
			$('.errorsettings_public_key').html('');
		}
		if (!settings_secret_key) {
			errors++;
			$('.errorsettings_secret_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_secret_key){
			$('.errorsettings_secret_key').html('');
		}*/
	  /*if (!settings_domain_one) {
			errors++;
			$('.errorsettings_domain_title1').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_domain_one){	
			var pattern = new RegExp("^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}$");

			if(pattern.test(settings_domain_one)){
				$('.errorsettings_domain_title1').html('');				
			}else{
				errors++;
				$('.errorsettings_domain_title1').html('<span class="errorspan">Incorrect domain name</span>');
			}			
			
		}else{
			$('.errorsettings_domain_title1').html('');
		}
		
		if (settings_domain_two) {
			var pattern = new RegExp("^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}$");

			if(pattern.test(settings_domain_two)){
				$('.errorsettings_domain_title2').html('');				
			}else{
				errors++;
				$('.errorsettings_domain_title2').html('<span class="errorspan">Incorrect domain name</span>');
			}	
		}else{
			$('.errorsettings_domain_title2').html('');
		}*/
		
/*
		this.setState({
			errors: errors
		});
		return formIsValid;*/

			/*if (selectedFont[0].value === undefined || selectedFont[0].value === '') {
		$('.errorfont').html('<span class="errorspan">Please select the font</span>');
		errors++;
		}
		else {
		$('.errorfont').html('');
		}*/

		if (user_image_preview === undefined || user_image_preview === '') { 
		$('.errorcarimage').html('<span class="errorspan">Please fill the field</span>');
		errors++;
		}
		else {
		$('.errorcarimage').html('');
		}
		
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }

    componentWillReceiveProps(Props){
		console.log(Props,"propp")
    	if(Props.settings !== this.props.settings){
    		if(Object.keys(Props.settings).length > 0){
    			const formpayload = Props.settings[0].results;
    			//const defaultfont = Props.settings[0].defaultfont;
    			this.setState({settings_id:formpayload.settings_id});
    			this.setState({settings_site_title:formpayload.settings_site_title});
    			this.setState({settings_from_name:formpayload.settings_from_name});
    			this.setState({settings_admin_email:formpayload.settings_admin_email});
    			this.setState({settings_from_email:formpayload.settings_from_email});
    			this.setState({settings_mailpath:formpayload.settings_mailpath});
    			this.setState({settings_company_address:formpayload.settings_company_address});
    			this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
    			this.setState({settings_smtp_host:formpayload.settings_smtp_host});
    			this.setState({settings_smtp_user:formpayload.settings_smtp_user});
    			this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
    			this.setState({settings_smtp_port:formpayload.settings_smtp_port});
    			this.setState({settings_username:formpayload.settings_username});
    			this.setState({settings_mobileno:formpayload.settings_mobileno});
    			this.setState({settings_email_footer:formpayload.settings_email_footer});
    			this.setState({hexColor:formpayload.settings_site_background_color});
    			this.setState({settings_public_key:formpayload.settings_public_key});
    			this.setState({settings_secret_key:formpayload.settings_secret_key});
				this.setState({settings_embedcode:formpayload.settings_embedcode});
				this.setState({settings_access_code:formpayload.access_code});				
				this.setState({st_position_left:formpayload.st_position_left});
				this.setState({st_position_right:formpayload.st_position_right});
				this.setState({st_position_top:formpayload.st_position_top});
				this.setState({st_position_bottom:formpayload.st_position_bottom});
				this.setState({bookingid_prefix:formpayload.bookingid_prefix});
				this.setState({invite_friends_perc:formpayload.invite_friends_perc});
				this.setState({bookingid_prefix_readonly:formpayload.bookingid_prefix});
				this.setState({domainSets : formpayload.domainSets});
				this.setState({user_image_preview: formpayload.st_ap_icon});
				this.setState({loader_image_preview: formpayload.st_ap_loader});
				this.setState({loader_text: formpayload.st_ap_loader_text});
				this.setState({settings_location_input: formpayload.settings_location_input});
				/*this.setState({styleimported: defaultfont.styleimported});
				this.setState({stylefontfamily: defaultfont.stylefontfamily});
				
				this.fontStyle(defaultfont.styleimported,defaultfont.stylefontfamily);*/

				const defaultfont = Props.settings[0].defaultfont;
				if(defaultfont!== ''){
					this.setState({styleimported: defaultfont.styleimported});
					this.setState({stylefontfamily: defaultfont.stylefontfamily});
					this.fontStyle(defaultfont.styleimported,defaultfont.stylefontfamily);	
				}

				
    			if(formpayload.settings_mail_from_smtp === '1'){
    				this.setState({smtp_checked: true});
    			}else{
    				this.setState({smtp_checked: false});
    			}
    			if(formpayload.enable_live === '1'){
    				this.setState({stripe_live_checked: true,stripe_sandbox_checked:false });
    			}else{
    				this.setState({stripe_sandbox_checked: true,stripe_live_checked:false});
    			}

    			if(formpayload.default_location_point === 'pickup_location'){
    				this.setState({pickuplocation_checked: true,wherelocation_checked:false });
    			}else{
    				this.setState({pickuplocation_checked: false,wherelocation_checked:true });
    			}

    			 if(Props.settings[0].font!== '' && Props.settings[0].font!== null){
			        this.setselectedvalue(Props.settings[0].font);
			     }else{
			     	this.setState({selectedFont: [{value:'', label: 'Select'}]})
			     }

			     if(formpayload.st_plugin_position === 'fixed'){
    				this.setState({st_position_checked: true });
    			 }else{
    				this.setState({st_position_checked: false});
    			 }
				 
				if(formpayload.categories!== '' && formpayload.categories!== null){
			        this.setmultiselectedvalue(formpayload.categories,'categories');
			    }
				if(formpayload.flight_ticket_option!== '' && formpayload.flight_ticket_option!== null){
			        this.setmultiselectedvalue(formpayload.flight_ticket_option,'ticket');
			    }

    		}
    	}
    	  	if(Props.updatesetting !== this.props.updatesetting){
			
				if(Object.keys(Props.updatesetting).length > 0){
						
						this.setState({ Loading: false });
						if(Props.updatesetting[0].status != 'error'){
							const formpayload = Props.updatesetting[0].results;
							
							$('.success_message').html('<div class="status_sucess"><h3>'+ Props.updatesetting[0].message+'</h3></div>');
							this.setState({settings_id:formpayload.settings_id});
							this.setState({settings_site_title:formpayload.settings_site_title});
							this.setState({settings_from_name:formpayload.settings_from_name});
							this.setState({settings_admin_email:formpayload.settings_admin_email});
							this.setState({settings_from_email:formpayload.settings_from_email});
							this.setState({settings_mailpath:formpayload.settings_mailpath});
							this.setState({settings_company_address:formpayload.settings_company_address});
							this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
							this.setState({settings_smtp_host:formpayload.settings_smtp_host});
							this.setState({settings_smtp_user:formpayload.settings_smtp_user});
							this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
							this.setState({settings_smtp_port:formpayload.settings_smtp_port});
							this.setState({settings_username:formpayload.settings_username});
							this.setState({settings_mobileno:formpayload.settings_mobileno});
							this.setState({settings_email_footer:formpayload.settings_email_footer});
							this.setState({hexColor:formpayload.settings_site_background_color});
							this.setState({settings_public_key:formpayload.settings_public_key});
							this.setState({settings_secret_key:formpayload.settings_secret_key});
							this.setState({settings_domain_one:formpayload.settings_domain_one});
							this.setState({settings_domain_two:formpayload.settings_domain_two});
							this.setState({settings_embedcode:formpayload.settings_embedcode});
							this.setState({settings_access_code:formpayload.access_code});
							this.setState({st_position_left:formpayload.st_position_left});
							this.setState({st_position_right:formpayload.st_position_right});
							this.setState({st_position_top:formpayload.st_position_top});
							this.setState({st_position_bottom:formpayload.st_position_bottom});
							this.setState({bookingid_prefix:formpayload.bookingid_prefix});
							this.setState({invite_friends_perc:formpayload.invite_friends_perc});
							this.setState({domainSets: formpayload.domainSets});
							this.setState({user_image_preview: formpayload.st_ap_icon});
							this.setState({loader_image_preview: formpayload.st_ap_loader});

							if(formpayload.settings_mail_from_smtp === '1'){
								this.setState({smtp_checked: true});
							}else{
								this.setState({smtp_checked: false});
							}
							if(formpayload.enable_live === '1'){
								this.setState({stripe_checked: true});
							}else{
								this.setState({stripe_checked: false});
							}


							if(formpayload.st_plugin_position === 'fixed'){
								this.setState({st_position_checked: true });
							}else{
								this.setState({st_position_checked: false});
							}
							
								scrollToTop();

								setTimeout(
									function() {
										$('.success_message').html('');
										location.reload();
									}
									.bind(this),
									3000
									);
						}else{
							scrollToTop();
							$('.success_message').html('<div class="status_sucess"><h3>'+ Props.updatesetting[0].message+'</h3></div>');
							setTimeout(
								function() {
									$('.success_message').html('');										
								}
								.bind(this),
								3000
							);
						}
				}
    	}
    	if(Props.fontname !== this.props.fontname){
    		if(Object.keys(Props.fontname).length > 0){
    		    this.setState({ fonttype : Props.fontname[0].fontlist})

    		}
    	}
		
		

	}

	setselectedvalue(selectsers){
	    const fontArray = selectsers.map((fonttype, index) => ({
	     id: index,
	     name: fonttype,
	    }));
    // Dynamically create select list
    let fonts = [];
    fontArray.map(item =>
    fonts.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedFont : fonts})
  }
  
  setmultiselectedvalue(selectsersq,type){
	 
		const myArray = selectsersq.split(",");
		let arry = [];
		let setboolen = false;
		myArray.map((item) =>(
		
		 arry.push({  value: item, label: item}),
		 (item == 'Fly' ? (setboolen = true) : '')
			
		));
		
		
		
	  if(type == 'categories'){
		this.setState({optionSelected : arry, showFlightTicket: setboolen})
	  }else if(type == 'ticket'){
		 this.setState({optionTicketSelected : arry})
	  }
	  
	  
  }

	handleClick = () => {
	this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose = () => {
	this.setState({ displayColorPicker: false })
	};

	handleChange = (color) => {
	this.setState({hexColor : color.hex})
	this.setState({ color: color.rgb })
	};

	 copyCodeToClipboard(event){
		event.preventDefault;
		const el = this.textArea
		el.select()
		document.execCommand("copy")
   }

   myCallback = (domainsets,domainID) => {
	   
    this.setState({domainSets: domainsets,removeID: domainID});
   };
  
  removeImage=()=>{
  	this.setState({user_image_preview:''});
  }
  removeloaderImage=()=>{
  	this.setState({loader_image_preview:''});
  }

   handleChangeFont = selectedFont => {
      this.setState
	  ({ selectedFont});
      this.setState({ fontvalue : selectedFont.value });
      if(selectedFont.value === ''){
        //$('.errorfont').html('<span class="errorspan">Please select the font</span>');
      }else{
      	 $('.errorfont').html('');
		 var qs = require('qs');
		 var postObject = {
				tf_id: selectedFont.value,
				admin_id:  localStorage.getItem("admin_id")
				};				
		var t_this = this;
		  axios.post(apiUrl+"plugin/imported_font_style",qs.stringify(postObject))
		  .then(function (response) {
			  if(response.data.result_set != ''){	 
				  
				  var styleimported = response.data.result_set.tf_import_url;
				  var stylefontfamily = response.data.result_set.tf_font_family;
				  t_this.fontStyle(styleimported,stylefontfamily);			  
				  
			  }else{
				  console.log('No data');
			  }
			
		  })
		  .catch(function (error) {
			console.log(error);
		  });
      }
   }
   
	fontStyle = (styleimported,stylefontfamily) =>{
			  
			var css = document.createElement('style'); 
            css.type = 'text/css';        
            if (css.styleSheet) { 
                css.styleSheet.cssText = styleimported; 
			}
            else { 
                css.appendChild(document.createTextNode(styleimported)); 
			}              
            document.getElementsByTagName("head")[0].appendChild(css);
			
			$(".samplefont p").attr('style', 'font-family: '+ stylefontfamily +' !important'); 
	}



  render() {

	const Option = (props) => {
	  return (
		<div>
		  <components.Option {...props}>
			<input
			  type="checkbox"
			  checked={props.isSelected}
			  onChange={() => null}
			/>{" "}
			<label>{props.label}</label>
		  </components.Option>
		</div>
	  );
	};
	

	
  
  	const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${ this.state.hexColor }`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    const defaultScripturl = 'http://air.uxt.design/index.html/';
	let test1 = this.state.settings_access_code;
	//let test = Number(this.state.settings_access_code);
	const scripttype = '';
	
		
		
		
		let  tester ='<script src="https://admin-air.uxt.design/plugin.js"></script> <div data-client="'+test1+'"  id="ap_container"></div>';

	let { user_image_preview,loader_image_preview} = this.state;
	let carImg = '';
	let preview = '';
	
	let loaderImg = '';
	let loaderpreview = '';
	

	const fontArray = this.state.fonttype.map ((fonttype, index) => ({
	id: index,
	name: fonttype,
	}));
	// Dynamically create select list
	let fonts = [];
	fontArray.map(item =>
	fonts.push({ label: item.name.label, value: item.name.value }),
	);

	const {selectedFont} = this.state;

	if (user_image_preview!== null && user_image_preview!== '') {
	    carImg = carImageUrl + "/" + user_image_preview;
		preview = <img className="img_class" src={carImg} alt="" />;
	}
	if (loader_image_preview!== null && loader_image_preview!== '') {
	    loaderImg = carImageUrl + "/" + loader_image_preview;
		loaderpreview = <img className="img_class" src={loaderImg} alt="" />;
	}
	
	
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar />		

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="content-body">
			<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Settings</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Site Name:</label>
					<input type="text" name="settings_site_title" onChange={this.handleInputChange} className="form-control" value={this.state.settings_site_title} />
					<div className="errorsettings_site_title"></div>
				</div>
				<div className="form-group">					
					<label>From Name:</label>
					<input type="text" name="settings_from_name" onChange={this.handleInputChange} className="form-control"value={this.state.settings_from_name} />
					<div className="errorsettings_from_name"></div>
				</div>
				<div className="form-group">
					<label>From Email:</label>
					<input type="text" name="settings_from_email"  onChange={this.handleInputChange} className="form-control" value={this.state.settings_from_email} />
					<div className="errorsettings_from_email"></div>
				</div>
				<div className="form-group"> 
					<label>Email Footer Content:</label>
					<input type="text" className="form-control" name="settings_email_footer" onChange={this.handleInputChange} value={this.state.settings_email_footer}/>
					<div className="errorsettings_email_footer"></div>
				</div>
              <div className="font-section">
			  <div className="form-group">
					<label>Font:</label>
				     <Select 
                         options={fonts}  
                         value={selectedFont?selectedFont:{ value: '0', label: 'Select Font Type' }}
                         onChange={this.handleChangeFont}
                         placeholder="Select Font Type" />
					<div className="errorfont"></div>
					<div className="samplefont">
					<p>Hello World!</p></div>
				</div>
			 	
				
				</div>
			
			</div>
			<div className="form-right">

				<div className="form-group">					
					<label>Admin Email:</label>
					<input type="text" className="form-control" onChange={this.handleInputChange} name="settings_admin_email" value={this.state.settings_admin_email} />
					<div className="errorsettings_admin_email"></div>
				</div>
				
				<div className="form-group">
					<label>Contact No:</label>
					<input type="text" className="form-control" name="settings_mobileno"  onChange={this.handleInputChange} value={this.state.settings_mobileno}/>
					<div className="errorsettings_mobileno"></div>
				</div>
				<div className="form-group">
					<label>Travel Commission%:</label>
					<input type="text" className="form-control" name="settings_commission_percentage"  onChange={this.handleInputChange} value={this.state.settings_commission_percentage} />
					 <div className="errorsettings_commission_percentage"></div>
				</div>	
				<div className="site-backgroub-full">
				<div className="form-group site-bg-clr"> 
				<label>Site Background Color: </label>
						<div style={ styles.swatch } onClick={ this.handleClick }>
						<div style={ styles.color } />
						</div>
						{ this.state.displayColorPicker ? <div style={ styles.popover }>
						<div style={ styles.cover } onClick={ this.handleClose }/>
						<SketchPicker color={ this.state.color } onChange={ this.handleChange } />
						</div> : null }
				</div>
				<div className="default-point">
				<label>Default Point: </label>
				 <div className="form-group">
				    <input onChange={this.handleInputChange} type="radio" name="settings_enable_droplocation" checked={this.state.wherelocation_checked}/>
	                <span>Where To Location</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="settings_enable_pickuplocation" checked={this.state.pickuplocation_checked}/>
	                <span>Pickup Location</span>
                </div>
				</div>
				</div>
                <div className="">
				<div className="form-group">
					<label>Placeholder Text:</label>
					<input type="text" className="form-control" name="settings_location_input" onChange={this.handleInputChange}  value={this.state.settings_location_input}/>
					<div className="errorsettings_input_key"></div>
				</div>
				</div>	
				
			</div>				
			</div>	
			<div className="title">
				<h4>Mail Configuration Settings</h4>
			</div>
			<div className="smpt-mail-function">
				<div className="form-group">
						<input onChange={this.handleInputChange} type="checkbox"  name="settings_mail_from_smtp" checked={this.state.smtp_checked}/>
						<span>Send Mail From Smtp</span>
				</div>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Smtp Host:</label>
					<input type="text" className="form-control" name="settings_smtp_host" onChange={this.handleInputChange}  value={(this.state.settings_smtp_host != 'null')?this.state.settings_smtp_host:''}/>
					<div className="errorsettings_smtp_host"></div>
				</div>
				<div className="form-group">					
					<label>Smtp Username:</label>
					<input type="text" className="form-control" name="settings_smtp_user"  onChange={this.handleInputChange}  value={(this.state.settings_smtp_user != 'null')?this.state.settings_smtp_user:''} />
					<div className="errorsettings_smtp_user"></div>
				</div>					
				<div className="form-group">
					<label>Smtp Password:</label>
					<input type="password" className="form-control" name="settings_smtp_pass" onChange={this.handleInputChange}  value={(this.state.settings_smtp_pass != 'null')?this.state.settings_smtp_pass:''} />
				<div className="errorsettings_smtp_pass"></div>
				</div>
			</div>			
			<div className="form-right">
				<div className="form-group">					
					<label>Smtp Port:</label>
					<input type="text" className="form-control"  name="settings_smtp_port" onChange={this.handleInputChange}  value={(this.state.settings_smtp_port != 'null')?this.state.settings_smtp_port:''} />
				<div className="errorsettings_smtp_port"></div>
				</div>
				<div className="form-group"> 
					<label>Mail Path:</label>
					<input type="text" className="form-control" name="settings_mailpath" onChange={this.handleInputChange} placeholder=""  value={(this.state.settings_mailpath != 'null')?this.state.settings_mailpath:''}/>
				</div>
			</div>	
			</div>	
			<div className="title">
				<h4>Domain & Embed Code</h4>
			</div>
			<div className="form-row">
				<div className="form-left domain-url-function">
					
					<Addinputsimple myCallback={this.myCallback} domainname={this.state.domainSets}/>
				</div>	
				<div className="form-right">
					<div className="form-group">
						<label>Embed Code:</label>
						
						<p className="right-side-header"  id="embed_code" name="settings_embedcode"  title="Copy Text" id="text">{tester}</p>
						<span>Copy this code</span>
						<div className="errorsettings_embedcode_title"></div>
					</div>
					
				</div>				
			</div>
			<h5>Plugin Position (Numbers Only)</h5>
			<div className="form-row">			
				<div className="form-left">
					<div className="form-group">
						<label>Left : %</label>
						<input type="number" className="form-control" name="st_position_left"  onChange={this.handleInputChange} value={this.state.st_position_left}/>
						<div className="errorsettings_st_position_left"></div>
					</div>
					<div className="form-group">
						<label>Right : %</label>
						<input type="number" className="form-control" name="st_position_right"  onChange={this.handleInputChange} value={this.state.st_position_right}/>
						<div className="errorsettings_st_position_right"></div>
					</div>
					<div className="form-group">
						<label>Position : </label>
						<input onChange={this.handleInputChange} type="checkbox"  name="st_position" checked={this.state.st_position_checked}/>
	                    <span>Fixed</span>
					</div>
				</div>
				<div className="form-right">
					<div className="form-group">
						<label>Top : %</label>
						<input type="number" className="form-control" name="st_position_top"  onChange={this.handleInputChange} value={this.state.st_position_top}/>
						<div className="errorsettings_st_position_top"></div>
					</div>
					<div className="form-group">
						<label>Bottom : %</label>
						<input type="number" className="form-control" name="st_position_bottom"  onChange={this.handleInputChange} value={this.state.st_position_bottom}/>
						<div className="errorsettings_st_position_bottom"></div>
					</div>
				</div>
			</div>
			
			<h5>Order Prefix (Editable Only Once)</h5>
			<div className="form-row">			
				<div className="form-left">
					<div className="form-group">
						
						<input type="text" className="form-control" name="bookingid_prefix" onChange={this.handleInputChange} value={this.state.bookingid_prefix} readOnly={(this.state.bookingid_prefix_readonly!='' && this.state.bookingid_prefix_readonly != null)?'readOnly':''} autoComplete='off' />
						<div className="errorbookingid_prefix"></div>
					</div>
				</div>
			</div>
			
			<h5>Invite Friends : %</h5>
			<div className="form-row">			
				<div className="form-left">
					<div className="form-group">
						
						<input type="text" className="form-control" name="invite_friends_perc" onChange={this.handleInputChange} value={this.state.invite_friends_perc} autoComplete='off' />
						<div className="errorinvite_friends_perc"></div>
					</div>
				</div>
			</div>
			
			<h5>Categories :</h5>
			<div className="form-row">			
				<div className="form-left">
					<div className="form-group">
					 <span
						class="d-inline-block"
						data-toggle="popover"
						data-trigger="focus"
						data-content="Please selecet account(s)"
					  >
						<ReactSelect
						  options={categoriesOptions}
						  isMulti
						  closeMenuOnSelect={false}
						  hideSelectedOptions={false}
						  components={{
							Option
						  }}
						  onChange={this.handlemultiChange}
						  allowSelectAll={true}
						  value={this.state.optionSelected}
						/>
					</span>
					<div className="erroroptionSelected"></div>
					</div>
					
				</div>
			</div>
			
			
			<div className={(this.state.showFlightTicket == true)?'showFlightTicket':''}>
				<h5>Flight Ticket :</h5>
				<div className="form-row">			
					<div className="form-left">
						<div className="form-group">
						 <span
							class="d-inline-block"
							data-toggle="popover"
							data-trigger="focus"
							data-content="Please selecet account(s)"
						  >
							<ReactSelect
							  options={ticketOptions}
							  isMulti
							  closeMenuOnSelect={false}
							  hideSelectedOptions={false}
							  components={{
								Option
							  }}
							  onChange={this.handlemultiTicketChange}
							  allowSelectAll={true}
							  value={this.state.optionTicketSelected}
							/>
						</span>
						</div>
					</div>
				</div>
			</div>
				
			 <div className="form-group">
					<label>Icon:</label>
				   <div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
                </div>
                {preview}
                {user_image_preview?<a href="javascript:void(0);" onClick={this.removeImage}>X</a>:''}
                
             </div>
			 
			 
			 
			 <h5>Site Loader Text :</h5>
			<div className="form-row">			
				<div className="form-left">
					<div className="form-group">
						
						<input type="text" className="form-control" name="loader_text" onChange={this.handleInputChange} value={this.state.loader_text} autoComplete='off' />
						<div className="errorloader_text"></div>
					</div>
					
					<div className="form-group">
					<label>Site Loader :</label>
				   <div className="choose-file">
						{this.state.isHidden && <div className="image_success"></div>}
						<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeLoaderHandler} /></span>
					</div>
					{loaderpreview}
					{loader_image_preview?<a href="javascript:void(0);" onClick={this.removeloaderImage}>X</a>:''}
                
             </div>
			 
				</div>
			</div>
			  
	  

			<div className="title">
				<h4>Stripe Configuration</h4>
			</div>
		    <div className="form-group">
				    <input onChange={this.handleInputChange} type="radio" name="settings_enable_live" checked={this.state.stripe_live_checked}/>
	                <span>Live</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="settings_enable_sandbox" checked={this.state.stripe_sandbox_checked}/>
	                <span>SandBox</span>
            </div>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Public Key:</label>
					<input type="text" className="form-control" name="settings_public_key" onChange={this.handleInputChange}  value={this.state.settings_public_key}/>
					<div className="errorsettings_public_key"></div>
				</div>
				<div className="form-group">					
					<label>Secret Key:</label>
					<input type="password" className="form-control" name="settings_secret_key"  onChange={this.handleInputChange}  value={this.state.settings_secret_key} />
					<div className="errorsettings_secret_key"></div>
				</div>	
			</div>	
			</div>			
			<div className="btn-group export">	
				
                <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                <span className="load-data">Loading</span> 
                                } Submit
			    </button>	
			</div>

			</div>
			</form>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
	settings: state.settings,
	updatesetting: state.updatesetting,
	fontname : state.fontname
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	   getSettingData: (formPayload) =>{
       dispatch({ type: GET_SETTINGDATA, formPayload});
       },
       getFontName: () => {
        dispatch({ type: GET_FONTNAME });
      },
      getUpdateSetting: (formPayload) => {
      dispatch({ type: GET_UPDATESETTING, formPayload });
    }
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Setting));