import { SET_PROFILEDETAILS, SET_LOGINDATA, SET_FORGETPASSWORD, SET_CHANGEPASSWORD, SET_UPDATEPROFILE } from '../../actions';

export const login = (state = [], action) => {
  switch (action.type) {
	case SET_LOGINDATA:
      return [...action.value];  
    default: return state;
  }
}
/*========*/
export const profile = (state = [], action) => {
  switch (action.type) {
	case SET_PROFILEDETAILS:
      return [...action.value];  
    default: return state;
  }
}
/*========*/
export const forgetpassword = (state = [], action) => {
  switch (action.type) {
	case SET_FORGETPASSWORD:
      return [...action.value];  
    default: return state;
  }
}
/*========*/
export const changepassword = (state = [], action) => {
  switch (action.type) {
	case SET_CHANGEPASSWORD:
      return [...action.value];  
    default: return state;
  }
}
/*========*/
export const updateprofile = (state = [], action) => {
  switch (action.type) {
	case SET_UPDATEPROFILE:
      return [...action.value];  
    default: return state;
  }
}