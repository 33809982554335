/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_USERNOTIFICATION, SET_USERNOTIFICATION, GET_UPDATENOTIFICATION, SET_UPDATENOTIFICATION } from '../../actions';
import {apiUrl } from "../../components/general/Config/Config";
import Axios from 'axios';


export const watchGetNotification = function* () {
  yield takeEvery(GET_USERNOTIFICATION, workerGetNotification);
}

function* workerGetNotification({ formPayload }) {
  try {
      
 const result = yield call(getNotificationData, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_USERNOTIFICATION, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getNotificationData(formPayload) {
   return Axios.post(apiUrl+'notification/usernotification', formPayload);
}  



/*========Update Notification==============*/

export const watchGetUpdateNotification = function* () {
  yield takeEvery(GET_UPDATENOTIFICATION, workerGetUpdateNotification);
}

function* workerGetUpdateNotification({ formPayload }) {
  try {
      
 const result = yield call(getUpdateNotification, formPayload);
 console.log('GET_UPDATENOTIFICATION')
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_UPDATENOTIFICATION, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getUpdateNotification(formPayload) {
   return Axios.post(apiUrl+'notification/usernotificationsave', formPayload);
} 