import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import axios from 'axios';

import Header from '../../general/Layouts/Header';
import MenuSidebar from '../../general/Layouts/MenuSidebar';
import Pagination from "react-js-pagination";

import { scrollToTopValidate,PageTitle } from "../../general/Helpers/SettingHelper";

import { GET_DIRECTPAYMENTVIEWLIST,GET_DIRECTPAYMENTLIST  } from '../../../actions';
import moment from 'moment';

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
        directpaymentlist:'',
        quotetypelist:[],
    };
    
  
		var qs = require('qs');
		var admin_id = localStorage.getItem("admin_id");
		var loggedas = localStorage.getItem("loggedas");
		var postobject = {
			activePage: 1,
			search_all: '',
			search_type:'',
			admin_id: admin_id,
			loggedas: loggedas
		};
		this.props.getDirectPaymentList(qs.stringify(postobject));
		
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
    componentDidMount() {

        

   }

   handlePageChange(pageNumber) {

     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");

     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       search_type: this.state.search_type,
       admin_id: admin_id,
       loggedas: loggedas
     };
     this.props.getDirectPaymentList(qs.stringify(postobject))
   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    search_type: formPayload.search_type,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  this.props.getDirectPaymentList(qs.stringify(postObject));

} 



   componentWillReceiveProps(Props){
 console.log('tester1')
    if (Object.keys(Props.directpaymentlist).length > 0) {
		
		 console.log(Props.directpaymentlist[0]["status"],'tester2')
		 
      if (Props.directpaymentlist[0]["status"] === "success") {
		  console.log(Props.directpaymentlist[0]["status"],'tester3')
        this.setState({ totalRecord : Props.directpaymentlist[0].records_count, directpaymentlist: Props.directpaymentlist[0]["directpaymentlist"] });
        this.directpaymentlist();
      }
    }
     
   }

   handleClickSelecetType = (event) => {

    const selectedIndex = event.target.options.selectedIndex;
    console.log(event.target.options);
    var key = event.target.options[selectedIndex].getAttribute('data-key');
  
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
  
    var postObject = {             
      activePage   : 1,
      search_type : key,
      admin_id: admin_id,
      loggedas: loggedas         
    };
  
    this.props.getDirectPaymentList(qs.stringify(postObject));
  
    this.setState({
        Loading:true     
    })
  
  }
   directpaymentlist = () => { 
    var directpaymentlist = this.props.directpaymentlist[0];
	console.log(this.props.directpaymentlist,"directpaymentlist")
    if (directpaymentlist != "undefined" && directpaymentlist != null) {
      if (directpaymentlist.status == "success") {
        if (Object.keys(directpaymentlist).length > 0) {
          const helperlistDetails = directpaymentlist.directpaymentlist.map(
            (directpaymentlist, Index) => {

            let sno = Index+1;
            var istyle = { width: 100};
           
         var payment_type = directpaymentlist.payment_type == null ?  directpaymentlist.payment_type = 'N/A' : directpaymentlist.payment_type;
         var paid_status = directpaymentlist.paid_status == null ?  directpaymentlist.paid_status = 'N/A' : directpaymentlist.paid_status;

         var ride_status = directpaymentlist.ride_status == 'tostart' ?  directpaymentlist.paid_status = 'Assigned' : directpaymentlist.ride_status;
          var username = directpaymentlist.username == null ?  directpaymentlist.username = 'N/A' : directpaymentlist.username;
          return (
                <tr key={directpaymentlist.id}>
          <td>{sno}</td>
          <td>{directpaymentlist.booking_id }</td>
          <td>{directpaymentlist.type}</td>
          <td>{directpaymentlist.u_name}</td>
          <td>{directpaymentlist.from_location}</td>
          <td>{directpaymentlist.to_location}</td>
          <td>{directpaymentlist.passenger_count}</td>
           
          <td>${directpaymentlist.original_amount}</td>
          
          <td>{payment_type}</td>
          <td>{moment(directpaymentlist.date).format('MM/DD/YYYY')}</td>   
          <td className="actiongroup">
                    <Link to={{ pathname: '/directpayment-view/'+directpaymentlist.id}} className="" title="View">  <i className="fa fa-eye" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <tr>
            <td colspan="15" className="v-align-nr">
              No Resulttt
            </td>
          </tr>
        );
      }
    }else {
      return (
        <tr>
          <td colspan="15" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {
    const {quotetypelist} = this.state;
    var current = this;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="directpayment" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                 <h3>Direct Payments</h3>
                </div>

                <div className="form-group">
                <select  onChange={current.handleClickSelecetType}  value={this.state.search_type}> 
                <option data-key="">Search by quotation type</option>
                <option data-key="Hotel">Hotel</option>
                 <option data-key="Fly">Fly</option>
                  <option data-key="Car">Car</option>
                   <option data-key="Package">Package</option>
                    <option data-key="Fun">Fun</option>
                </select>
              <div className="errorassign_quotetype"></div>
              </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> 
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
			      <th>Quotation ID</th>
                  <th>Type</th>
                  <th>User</th>                  
                  <th>From location</th>
                  <th>To location</th>
                  <th>Passengers</th>
                  <th>Amount</th>
                  <th>Payment Type</th>
                  <th>Paid On</th>
                  <th>View Details</th>
                   </tr>
                  </thead>
                  <tbody>{this.directpaymentlist()}</tbody>
                </Table>
                </div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

const mapStateTopProps = (state) => { console.log(state,'stat')
  return {
    directpaymentlist: state.directpaymentlist
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDirectPaymentList: (formVal) =>{
      dispatch({ type: GET_DIRECTPAYMENTLIST, formVal });
   },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(List));