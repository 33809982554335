/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_SETTINGDATA, SET_SETTINGDATA, GET_UPDATESETTING, SET_UPDATESETTING } from '../../actions';
import {apiUrl } from "../../components/general/Config/Config";
import Axios from 'axios';
//import cookie from 'react-cookies';

export const watchGetSettingData = function* () {
  yield takeEvery(GET_SETTINGDATA, workerGetSettingData);
}

function* workerGetSettingData({ formPayload }) {
  try {
      
 const result = yield call(getSettingData, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_SETTINGDATA, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getSettingData(formPayload) {
   return Axios.post(apiUrl+'adminpanel/adminsettings', formPayload);
}  

/*save the settings*/

export const watchGetUpdateSetting = function* () {
  yield takeEvery(GET_UPDATESETTING, workerGetUpdateSetting);
}

function* workerGetUpdateSetting({ formPayload }) {
  try {
      
 const result = yield call(getUpdateSetting, formPayload);
  var resultArr = [];
  resultArr.push(result.data);
    yield put({ type: SET_UPDATESETTING, value: resultArr });
  } 
  catch {
    console.log('post data failed');
  }
} 


function getUpdateSetting(formPayload) {
   return Axios.post(apiUrl+'adminpanel/settingsave', formPayload);
} 