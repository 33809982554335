import { SET_USERLIST, SET_USERDETAILLIST, SET_ADDUSER, SET_EDITUSER, SET_IMPORTUSER, SET_EXPORTUSER } from '../../actions';

export const userlist = (state = [], action) => {
  switch (action.type) {
	case SET_USERLIST:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
export const userdetails = (state = [], action) => {
  switch (action.type) {
	case SET_USERDETAILLIST:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
export const useradd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDUSER:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
export const useredit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITUSER:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
export const userimport = (state = [], action) => {
  switch (action.type) {
	case SET_IMPORTUSER:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
export const exportuser = (state = [], action) => {
  switch (action.type) {
	case SET_EXPORTUSER:
      return [...action.value];  
    default: return state;
  }
}
