import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../general/Layouts/Header';
import MenuSidebar from '../../general/Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../../general/Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../../general/Helpers/SettingHelper";
import { licenseURL, insuranceURL, profileURL, brokerURL } from '../../general/Config/Config';
//import dummylicense from '../../../common/images/broker-license.jpg';
import pdficon from '../../../common/images/pdf.svg';
import {GET_EDITBROKER,GET_BROKERDETAIL}  from '../../../actions'; 

class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
		const brokerid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
			brokerid: brokerid,
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			password:'',
			status:'',
			changepassword:'',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
				
			broker_license:'',
			profile_image:'',
			license:'',
			insurance	:'',
			profile_checked:false,
			license_checked:false,
			insurance_checked:false,
			bankaccdetail_checked: false,
			admin_id: localStorage.getItem('admin_id'),
			
			
		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleVerified = this.handleVerified.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	  
	  
	   this.props.getBrokerDetail(brokerid);
    }

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }
    }
   handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
    handleVerified(event){

    	const {name} = event.target;
		if(name === 'enable_license'){
		 this.setState({license_checked: !this.state.license_checked});
		}	
		if(name === 'enable_profile'){
		 this.setState({profile_checked: !this.state.profile_checked});
		}	
		if(name === 'enable_insurance'){
		 this.setState({insurance_checked: !this.state.insurance_checked});
		}
		if(name === 'enable_bankaccdetail'){
		 this.setState({bankaccdetail_checked: !this.state.bankaccdetail_checked});
		}		
		
		
    }
	componentDidMount() {
		document.title = PageTitle('user Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
    }

  

 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				

				let insurance_verified;
				if(formPayload.insurance_checked === true){
					insurance_verified = 'yes';
				}else{
					insurance_verified = 'no';
				}
				let license_verified;
				if(formPayload.license_checked === true){
					license_verified = 'yes';
				}else{
					license_verified = 'no';
				}
				let profile_verified;
				if(formPayload.profile_checked === true){
					profile_verified = 'yes';
				}else{
					profile_verified = 'no';
				}
				
				let bankaccdetail_verified;
				if(formPayload.bankaccdetail_checked === true){
					bankaccdetail_verified = 'yes';
				}else{
					bankaccdetail_verified = 'no';
				}

				var postObject = {
			        admin_id            : localStorage.getItem("admin_id"),
					userid              : formPayload.userid,
					username            : formPayload.username,
					firstname           : formPayload.firstname,
					lastname            : formPayload.lastname,
					email               : formPayload.email,
					phoneno             : formPayload.phoneno,
					

					currentpswd         : formPayload.password,
					changepassword      : formPayload.changepassword,
					profile_verified    : profile_verified,
					license_verified    : license_verified,
					insurance_verified  : insurance_verified,
					bankaccdetail_verified  : bankaccdetail_verified,
					status              : status,
				};
				this.props.getEditBroker(qs.stringify(postObject));
			}
	}

	validateForm() {
		const {username,firstname, lastname, email, phoneno, password, changepassword,broker_license} = this.state;
      	//let formIsValid = true;
		let errors = 0;
		if (!username) {
			errors++;
			$('.errorusername').html('<span class="errorspan">Please fill the field</span>');
		}else if(username){
			$('.errorusername').html('');
		}

		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			$('.errorfirstname').html('');
		}

		if (!lastname) {
			errors++;
			$('.errorlastname').html('<span class="errorspan">Please fill the field</span>');
		}else if(lastname){
			$('.errorlastname').html('');
		}
		if (!email) {
			errors++;
			$('.erroremail').html('<span class="errorspan">Please fill the field</span>');
		}else if(email){
			$('.erroremail').html('');
		}
		if (!phoneno) {
			errors++;
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		}else if(phoneno){
			$('.errorphoneno').html('');
		}

		if(password){
			if(!changepassword){
				errors++;
			   $('.errorchangepaswd').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorchangepaswd').html('');
			}
		}else{
			$('.errorchangepaswd').html('');
		}

		

		if (!broker_license) {
		$('.errorbrokerlicense').html('<span class="errorspan">Please enter the license number</span>');
		errors++;
		}
		else {
		$('.errorbrokerlicense').html('');
		}

		

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){
    	   if(Props.brokerdetail !== this.props.brokerdetail){

    		if(Object.keys(Props.brokerdetail).length > 0){

				this.setState({Loading:false});
					//console.log(Props.carlist);
					if(Props.brokerdetail[0].status === "success"){

						const formdata = Props.brokerdetail[0].brokerlist;
				
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({username:formdata.username});
						this.setState({firstname:formdata.firstname});
						this.setState({lastname:formdata.lastname});
						this.setState({email:formdata.email});
						this.setState({phoneno:formdata.mobileno});
						this.setState({broker_license:formdata.broker_license});
						this.setState({userid: formdata.id});
						this.setState({profile_image: formdata.profile_image})
						this.setState({insurance: formdata.insurance})
						this.setState({license: formdata.file_license})
						
						
						

				        if(formdata.insurance_verified == 'yes'){
				        	this.setState({insurance_checked: true})
				        }
				        if(formdata.license_verified == 'yes'){
				        	this.setState({license_checked: true})
				        }
				        if(formdata.profile_verified == 'yes'){
				        	this.setState({profile_checked: true})
				        }
						if(formdata.bankaccdetail_verified == 'yes'){
					    	this.setState({bankaccdetail_checked: true})
					    }
					}
    		}
    	}

	
         if(Props.brokeredit !== this.props.brokeredit){
    		if(Object.keys(Props.brokeredit).length > 0){
    					this.setState({ Loading: false });

				if(Props.brokeredit[0].status === "success"){

    			const formdata = Props.brokeredit[0].brokerlist;
				if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
			    this.setState({username:formdata.username});
				this.setState({firstname:formdata.firstname});
				this.setState({lastname:formdata.lastname});
				this.setState({email:formdata.email});
				this.setState({phoneno:formdata.mobileno});
				this.setState({userid: formdata.id});
                $('.success_message').html('<div class="status_sucess"><h3>'+ Props.brokeredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			}else{
    				 $('.success_message').html('<div class="status_sucess"><h3>'+ Props.brokeredit[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
    			}
    		}
    	}   
    	 

		
   }

  

	
  render() {

  	const {selectedOption, profile_image, license, insurance} = this.state;

  	
	  	
	    let file ='';
	  	if(profile_image && profile_image!=='' && profile_image!== null){
		    file = profileURL + "/" + profile_image;
	    }
	    let brokerlic ='';
	  	if(license && license!=='' && license!== null){
		    brokerlic = licenseURL + "/" + license;
	    }
	     let brokerins ='';
	  	if(insurance && insurance!=='' && insurance!== null){
		    brokerins = insuranceURL + "/" + insurance;
	    }

  	

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="brokers" />  	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
		    	<div className="form-group">
					<label>User Name:</label>
				    <input type="text" name="username" onChange={this.handleInputChange} className="form-control" value={this.state.username} disabled="true"/>
					<div className="errorusername"></div>
				</div>
				<div className="form-group">
					<label>Last Name:</label>
				    <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
					<div className="errorlastname"></div>
				</div>
				<div className="form-group">
					<label>Phone number:</label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				<div className="form-group">
					<label>License</label>
					<input type="text" name="broker_license" onChange={this.handleInputChange} className="form-control" value={this.state.broker_license} />
					<div className="errorbrokerlicense"></div>
				</div>
				
				<div className="form-group">
					<label>Current password:</label>
				    <input type="password" name="password" onChange={this.handleInputChange} className="form-control" value={this.state.password} />
					<div className="errorpassword"></div>
				</div>
				<div className="form-group">
					<label>Reset password:</label>
				    <input type="text" name="changepassword" onChange={this.handleInputChange} className="form-control" value={this.state.changepassword} disabled={this.state.disablepasssword}/>
					<div className="errorchangepaswd"></div>
				</div>
				</div>
			 <div className="form-right">
				<div className="form-group">
					<label>First Name:</label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				<div className="form-group">
					<label>Email:</label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>
				
				
				<div className="form-group">					
					<label>Status:</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
			</div>		
			<div className="brokers-deatils">
			<div className="title">
				<h4>Details given by brokers</h4>
			</div>
			<div className="form-row">
				<div className="form-group">
				    <span>Profile verification</span>
					<input onChange={this.handleVerified} type="checkbox"  name="enable_profile" checked={this.state.profile_checked}/>
					<div className="profile-photo">
					<img
					src={file}
					type='application/pdf'
					title='title'
					/>
					</div>
				</div>
				<div className="form-group">
				    <span>License verification</span>
					<input onChange={this.handleVerified} type="checkbox"  name="enable_license" checked={this.state.license_checked}/>					
					<a target="_blank" className="broker-icon" href={brokerlic}>
					<img
					src={pdficon}
					type='application/pdf'
					title='title'
					/></a>
				</div>
			<div className="form-group">
			  <span>Insurance verification</span>
					<input onChange={this.handleVerified} type="checkbox"  name="enable_insurance" checked={this.state.insurance_checked}/>
					<a target="_blank" className="broker-icon" href={brokerins}>
					<img
					src={pdficon}
					type='application/pdf'
					title='title'
					/></a>
			</div>
			
			<div className="form-group">
				    <span>Bank Account/Card Verification</span>
					<input onChange={this.handleVerified} type="checkbox" name="enable_bankaccdetail" checked={this.state.bankaccdetail_checked}/>
					
				</div>
				
			</div>
		</div>

			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


const mapStateTopProps = (state) => {
  return {
  	
  	brokerdetail   : state.brokerdetail,
  	brokeredit     : state.brokeredit
  	
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  	
  	getBrokerDetail: (brokerid) =>{
       dispatch({ type: GET_BROKERDETAIL,brokerid });
    },
    getEditBroker: (formPayload) =>{
       dispatch({ type: GET_EDITBROKER, formPayload});
    }
    
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Edit));