

/*=========*/
import {settings} from "./flight/settingsAll";
import {updatesetting} from './flight/settingsAll';

import {login} from "./flight/profileAll";
import {profile} from './flight/profileAll';
import {changepassword} from "./flight/profileAll";
import {forgetpassword} from './flight/profileAll';
import {updateprofile} from './flight/profileAll';


import {companylist} from './flight/companyAll';
import {companyadd} from './flight/companyAll';
import {companyedit} from './flight/companyAll';


import {userlist} from './flight/userAll';
import {useradd} from './flight/userAll';
import {useredit} from './flight/userAll';
import {userimport} from './flight/userAll';
import {userdetails} from './flight/userAll';
import {exportuser} from './flight/userAll';

import {templatelist} from './flight/templateAll';
import {templatedetail} from './flight/templateAll';
import {templateadd} from './flight/templateAll';
import {templatedelete} from './flight/templateAll';
import {templateedit} from './flight/templateAll';

import {fontlist} from './flight/fontAll';
import {fontname} from './flight/fontAll';
import {fontadd} from './flight/fontAll';
import {fontdelete} from './flight/fontAll';

import quotelist from './flight/quotelist';
import quoteview from './flight/quoteview';

import {directpaymentlist} from './flight/directpaymentAll';
import {directpaymentview} from './flight/directpaymentAll';


import {brokerlist,brokeredit,brokerstatus,brokerstripelink,exportbroker,brokerdetail,brokerdelete} from './flight/brokerAll';
//import {brokerimport} from './flight/brokerAll';


/*=========*/

import {notification, updatenotification} from './general/notificationAll';
import {cmsadd, cmsedit, cmsdetail, cmslist, cmsactive} from './general/cmsAll';
import {paymentlog} from './general/package';
import { combineReducers } from "redux";



const rootReducer = combineReducers({
      login: login,
      settings      : settings,
      updatesetting : updatesetting,
      changepassword: changepassword,
      forgetpassword: forgetpassword,
      profile       : profile,
      updateprofile : updateprofile, 
      companylist   : companylist,
      companyadd   : companyadd,
      companyedit   : companyedit,
      brokerlist: brokerlist,
      brokeredit: brokeredit,
      //          brokerimport: brokerimport,
      brokerstatus: brokerstatus,
      brokerstripelink: brokerstripelink,
      exportbroker: exportbroker,
      brokerdetail: brokerdetail,
      brokerdelete: brokerdelete,
      fontlist           : fontlist,
      fontname           : fontname,
      fontadd            : fontadd, 
      userlist			 : userlist,
      exportuser     : exportuser,
      fontdelete         : fontdelete, 
      useredit: useredit,
      userimport: userimport,
      userdetails: userdetails,
      useradd      : useradd, 
      templatelist  : templatelist,
      templatedetail: templatedetail,
      templateadd   : templateadd,
      templatedelete : templatedelete,
      templateedit: templateedit,
      cmsadd        : cmsadd,
      cmslist       : cmslist,
      cmsedit       : cmsedit,
      cmsdetail     : cmsdetail,
      cmsactive     : cmsactive, 
      notification     : notification, 
      updatenotification     : updatenotification, 
       quotelist : quotelist,
     quoteview : quoteview,
	 directpaymentlist : directpaymentlist,
     directpaymentview : directpaymentview, 	 
     paymentlog: paymentlog
});

export default rootReducer;
