import { SET_QUOTEVIEWLIST } from '../../actions';

const quoteview = (state = [], action) => {
  switch (action.type) {
	case SET_QUOTEVIEWLIST:
      return [...action.value];  
    default: return state;
  }
}

export default quoteview;
