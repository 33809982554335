import {SET_ADDCMS, SET_ACTIVATECMS, SET_CMSDETAIL, SET_EDITCMS, SET_CMSLIST } from '../../actions';

export const cmsadd = (state = [], action) => {
  switch (action.type) {
	case SET_ADDCMS:
      return [...action.value];  
    default: return state;
  }
}


export const cmsactive = (state = [], action) => {
  switch (action.type) {
	case SET_ACTIVATECMS:
      return [...action.value];  
    default: return state;
  }
}


export const cmsdetail = (state = [], action) => {
  switch (action.type) {
	case SET_CMSDETAIL:
      return [...action.value];  
    default: return state;
  }
}

export const cmsedit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITCMS:
      return [...action.value];  
    default: return state;
  }
}


export const cmslist = (state = [], action) => {
  switch (action.type) {
	case SET_CMSLIST:
      return [...action.value];  
    default: return state;
  }
}
