import { SET_BROKERLIST, SET_EDITBROKER, SET_IMPORTBROKER, SET_ACTIVATEBROKER, SET_STRIPELINKTOBROKER, SET_EXPORTBROKER, SET_BROKERDETAIL,SET_DELETEBROKER } from '../../actions';

export const brokerlist = (state = [], action) => {
  switch (action.type) {
	case SET_BROKERLIST:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
export const brokeredit = (state = [], action) => {
  switch (action.type) {
	case SET_EDITBROKER:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
/*export const brokerimport = (state = [], action) => {
  switch (action.type) {
	case SET_IMPORTBROKER:
      return [...action.value];  
    default: return state;
  }
}*/
/*===========*/
export const brokerstatus = (state = [], action) => {
  switch (action.type) {
	case SET_ACTIVATEBROKER:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
export const brokerstripelink = (state = [], action) => {
  switch (action.type) {
	case SET_STRIPELINKTOBROKER:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
export const exportbroker = (state = [], action) => {
  switch (action.type) {
	case SET_EXPORTBROKER:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/
export const brokerdetail = (state = [], action) => {
  switch (action.type) {
	case SET_BROKERDETAIL:
      return [...action.value];  
    default: return state;
  }
}
/*===========*/

export const brokerdelete = (state = [], action) => {
  switch (action.type) {
	case SET_DELETEBROKER:
      return [...action.value];  
    default: return state;
  }
}


